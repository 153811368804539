import {
    detailFilterTypeValues,
    standardFilterTypeValues,
    themeFilterTypeValues
} from "@/consts/caseFilterTypeValues";
import queryService from "./queryService";
import { ListCaseFilter, SortParams } from "@/models/cases/CaseFilter";
import { Route } from "vue-router";

export function getCaseFilterFromQuery(query: Route["query"]): ListCaseFilter {
    const {
        mailboxes,
        sortBy = [],
        sortDesc = []
    } = queryService.getValues(query, {
        mailboxes: [""],
        sortBy: [""],
        sortDesc: [false]
    });

    const filterSortBy = {} as SortParams & Record<string, unknown>;
    sortBy.forEach((s, i) => (filterSortBy[s] = sortDesc[i] ?? false));

    return {
        mailboxes,
        standard: queryService.getValues(query, standardFilterTypeValues),
        detail: queryService.getValues(query, detailFilterTypeValues),
        theme: queryService.getValues(query, themeFilterTypeValues),
        sortBy: filterSortBy
    };
}
