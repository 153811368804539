
import Vue from "vue";
import Component from "vue-class-component";
import { ModelSync, Prop, Ref } from "vue-property-decorator";
import BaseDialog from "@/components/BaseDialog";
import PostQualificationCreateForm from "@/components/PostQualificationCreateForm";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: {
        BaseDialog,
        PostQualificationCreateForm
    }
})
export default class PostQualificationCreateDialog extends Vue {
    @ModelSync("value", "input", { type: Boolean })
    valueSync!: boolean;
    @Prop({ type: Number, required: true }) readonly contactId!: number;
    @Prop({ type: Number, required: true }) readonly caseId!: number;
    @Prop(String) readonly defaultEmail!: string;

    @Ref() form!: VuetifyForm;

    close(): void {
        this.valueSync = false;
    }
}
