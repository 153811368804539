
import { CaseLink } from "@/models/cases/CaseLink";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import dateFormat from "dateformat";
import { ParentCaseLink } from "@/models/cases/ParentCaseLink";
import { orderBy } from "lodash";

@Component
export default class CaseLinks extends Vue {
    @Prop({ type: Object, required: true })
    parentLink!: ParentCaseLink;

    @Prop({ type: Number, required: true })
    selectedId!: number;

    get orderedChildren(): CaseLink[] {
        return orderBy(this.parentLink.children, (l) => l.caseId);
    }

    getLinkLabel(link: CaseLink): string {
        const dateString = dateFormat(link.createdDateTime, "dd.mm.yyyy");
        return `${dateString} (#${link.internalId})`;
    }
}
