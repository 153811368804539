
import { Component, Prop, PropSync } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class SwitchAccordion extends Vue {
    @Prop({ type: String, required: true })
    public readonly title!: string;
    @PropSync("open", { type: Boolean, required: true })
    public isOpen!: boolean;

    get isPanelOpen(): number | undefined {
        return this.isOpen ? 0 : undefined;
    }

    set isPanelOpen(state: number | undefined) {
        if (state !== undefined && state >= 0) this.isOpen = true;
        else this.isOpen = false;
    }
}
