import { isArray, isEqual } from "lodash";
import { Location, Route } from "vue-router";
import router from "@/router";

export type Query = Exclude<Location["query"], undefined>;
export type QueryValue = string | boolean | number | null | undefined;
export type QueryValues = Record<string, QueryValue | QueryValue[]>;

async function setValues(
    query: Route["query"],
    values: QueryValues
): Promise<void> {
    const queryValues = {} as Query;
    Object.keys(values).forEach((k) => {
        const value = values[k];
        queryValues[k] = isArray(value)
            ? value.length > 1 // need this to check equality properly
                ? value.map((v) => v?.toString() ?? null)
                : value[0]?.toString()
            : value?.toString();
    });

    const newQueryParams = {
        ...query,
        ...queryValues
    };

    if (isEqual(query, newQueryParams)) return;
    await router.replace({
        query: newQueryParams
    });
}

function getValues<T extends QueryValues>(
    query: Route["query"],
    typeObj: T
): Partial<T> {
    const result = {} as Record<string, QueryValue | QueryValue[]>;
    Object.keys(typeObj).forEach(
        (key) => (result[key] = getValue(typeObj[key], query[key]))
    );

    return result as Partial<T>;
}

function getValue(
    objValue: QueryValue | QueryValue[],
    value: string | (string | null)[] | null | undefined
): QueryValue | QueryValue[] {
    if (isArray(objValue)) {
        const typeValue = objValue[0];
        if (value) {
            return isArray(value)
                ? value.map((v) => parse(typeValue, v))
                : [parse(typeValue, value)];
        }
        return value;
    }

    const typeValue = objValue;
    return isArray(value)
        ? parse(typeValue, value[0])
        : parse(typeValue, value);
}

function parse(
    valueOfType: QueryValue,
    value: string | null | undefined
): QueryValue {
    if (value === null || value === undefined) return value;

    switch (typeof valueOfType) {
        case "boolean":
            return value === "true";
        case "number":
            return parseFloat(value);
        case "string":
            return value;
        default:
            return undefined;
    }
}

export default {
    setValues,
    getValues
};
