
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import SideFilter from "@/components/SideFilter";
import SwitchAccordion from "@/components/SwitchAccordion";
import DatePicker from "@/components/DatePicker";
import { module as casesModule } from "@/store/modules/cases";
import { CasesGetters } from "@/store/modules/cases/getters";
import { CaseInfo } from "@/models/cases/CaseInfo";
import {
    FullTextSearchType,
    StandardFilter,
    DetailFilter,
    ThemeFilter
} from "@/models/cases/CaseFilter";
import { LookupEntity } from "@/models/LookupEntity";
import {
    getSelectDataSource,
    getSelectDataSourceFromEnum,
    getSortedSelectDataSource
} from "@/services/dataSourceUtils";
import { MarketGetters } from "@/store/modules/market/getters";
import { MarketActions } from "@/store/modules/market/actions";
import { marketModule } from "@/store/modules/market";
import { Market } from "@/models/markets/Market";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import { CodeLevelData } from "@/models/CodeLevelData";
import { CodeLevelActions } from "@/store/modules/codeLevel/actions";
import { BoardCaseLanguage } from "@/models/cases/BoardCaseLanguage";
import { CodeLevelLookupEntity } from "@/models/CodeLevelLookupEntity";
import { isArray } from "lodash";
import { LookupEntityWithOrder } from "@/models/LookupEntityWithOrder";
import queryService from "@/services/queryService";
import {
    detailFilterTypeValues,
    standardFilterTypeValues,
    themeFilterTypeValues
} from "@/consts/caseFilterTypeValues";
import { makeRequest } from "@/services/requestUtils";
import caseApi from "@/api/caseApi";
import downloadUtils from "@/services/downloadUtils";
import { getCaseFilterFromQuery } from "@/services/queryFactoryUtil";
import { CasesActions } from "@/store/modules/cases/actions";
import { Route } from "vue-router";
import caseFilterService from "@/services/caseFilterService";
import { isUserInRoles } from "@/services/userUtils";
import { roles } from "@/consts/roles";
import { CasesMutation } from "@/store/modules/cases/mutations";
import * as caseStatuses from "@/consts/caseStatuses";

type CodeLevelArea = CodeLevelData["codeLevelAreas"][0];
type CodeLevelTopicGroup = CodeLevelArea["topicGroups"][0];

@Component({
    components: {
        SideFilter,
        SwitchAccordion,
        DatePicker
    },
    computed: {
        ...casesModule.mapGetters({
            info: CasesGetters.Info,
            isInfoLoading: CasesGetters.IsLoading,
            arePresetsApplied: CasesGetters.ArePresetsApplied
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets,
            isLoading: MarketGetters.IsLoading,
            regions: MarketGetters.Regions
        }),
        ...codeLevelModule.mapGetters({
            codeLevelData: CodeLevelGetters.CodeLevelData,
            loading: CodeLevelGetters.IsLoading
        })
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo
        }),
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets,
            loadRegions: MarketActions.LoadRegions
        }),
        ...codeLevelModule.mapActions({
            loadCodeLevels: CodeLevelActions.LoadCodeLevels
        }),
        ...casesModule.mapMutations({
            setArePresetsApplied: CasesMutation.SetArePresetsApplied
        })
    }
})
export default class CaseSideFilter extends Vue {
    readonly newReopenedStatus = "New & Reopened";
    readonly languages = getSelectDataSourceFromEnum(BoardCaseLanguage);
    protected isStandardFilterOpened = true;
    protected isDetailFilterOpened = false;
    protected isThemeFilterOpened = false;
    protected readonly info!: CaseInfo;
    protected readonly isInfoLoading!: boolean;
    protected readonly arePresetsApplied!: boolean;
    protected readonly markets!: Market[];
    protected readonly regions!: string[];
    protected isExportLoading = false;
    protected readonly fullTextSearchType = getSelectDataSourceFromEnum(
        FullTextSearchType,
        false
    );
    protected readonly loadInfo!: () => Promise<void>;
    protected readonly loadMarkets!: () => Promise<void>;
    protected readonly loadRegions!: () => Promise<void>;
    protected readonly loadCodeLevels!: () => Promise<void>;
    protected readonly setArePresetsApplied!: (value: boolean) => void;

    protected readonly codeLevelData!: CodeLevelData | null;
    protected readonly loading!: boolean;

    public standardFilter: StandardFilter = {};
    public detailFilter: DetailFilter = {};
    public themeFilter: ThemeFilter = {
        codeLevelAreaId: undefined,
        codeLevelBrandId: undefined,
        codeLevelTopicDetailId: undefined,
        codeLevelTopicGroupId: undefined,
        codeLevelTypeId: undefined,
        includeAllSets: undefined
    };

    get codeLevelTypes(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.codeLevelData?.codeLevelTypes.map((c) => ({
                id: c.id,
                name: this.getCodeLevelName(c)
            })) ?? []
        );
    }

    get codeLevelBrands(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.codeLevelData?.codeLevelBrands.map((c) => ({
                id: c.id,
                name: this.getCodeLevelName(c)
            })) ?? []
        );
    }

    get codeLevelAreas(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.codeLevelData?.codeLevelAreas.map((a) => ({
                id: a.id,
                name: this.getCodeLevelName(a)
            })) ?? []
        );
    }

    get selectedCodeLevelArea(): CodeLevelArea | null {
        return (
            this.codeLevelData?.codeLevelAreas.find(
                (a) => a.id === this.themeFilter.codeLevelAreaId
            ) ?? null
        );
    }

    get codeLevelTopicGroups(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.selectedCodeLevelArea?.topicGroups.map((t) => ({
                id: t.id,
                name: this.getCodeLevelName(t)
            })) ?? []
        );
    }

    get selectedCodeLevelTopicGroup(): CodeLevelTopicGroup | null {
        return (
            this.selectedCodeLevelArea?.topicGroups.find(
                (t) => t.id === this.themeFilter.codeLevelTopicGroupId
            ) ?? null
        );
    }

    get codeLevelTopicDetails(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.selectedCodeLevelTopicGroup?.codeLevelTopicDetails.map(
                (d) => ({
                    id: d.id,
                    name: this.getCodeLevelName(d)
                })
            ) ?? []
        );
    }

    // CodeLevelSet cascading related values
    get codeLevelAreaValue(): number | undefined {
        return this.themeFilter.codeLevelAreaId;
    }

    set codeLevelAreaValue(value: number | undefined) {
        if (this.themeFilter.codeLevelAreaId !== value) {
            this.themeFilter.codeLevelTopicGroupId = undefined;
            this.themeFilter.codeLevelTopicDetailId = undefined;
        }
        this.themeFilter.codeLevelAreaId = value;
    }

    get codeLevelTopicGroupValue(): number | undefined {
        return this.themeFilter.codeLevelTopicGroupId;
    }

    set codeLevelTopicGroupValue(value: number | undefined) {
        if (this.themeFilter.codeLevelTopicGroupId !== value) {
            this.themeFilter.codeLevelTopicDetailId = undefined;
        }
        this.themeFilter.codeLevelTopicGroupId = value;
    }

    get codeLevelTopicDetailValue(): number | undefined {
        return this.themeFilter.codeLevelTopicDetailId;
    }

    set codeLevelTopicDetailValue(value: number | undefined) {
        this.themeFilter.codeLevelTopicDetailId = value;
    }

    get filterStatusValue(): number | number[] | undefined {
        return (
            this.standardFilter.statusId ??
            this.standardFilter.multipleStatusIds
        );
    }
    set filterStatusValue(value: number | number[] | undefined) {
        if (isArray(value)) {
            this.standardFilter.multipleStatusIds = value;
            this.standardFilter.statusId = undefined;
        } else {
            this.standardFilter.statusId = value;
            this.standardFilter.multipleStatusIds = undefined;
        }
    }

    get inputChannels(): LookupEntity<unknown>[] {
        if (!this.info) return [];

        return getSelectDataSource(this.info.inputChannels);
    }

    get statuses(): LookupEntity<number | number[] | null>[] {
        if (!this.info) return [];

        const statuses = getSortedSelectDataSource(this.info.statuses);
        this.fillStatusesWithCombinedStatus(statuses, this.info.statuses);
        return statuses;
    }

    get sources(): LookupEntity<unknown>[] {
        if (!this.info) return [];

        return getSelectDataSource(this.info.sources);
    }

    get marketDataSource(): LookupEntity<unknown>[] {
        if (!this.markets) return [];

        return getSelectDataSource(
            this.markets.map((m) => ({
                id: m.marketId,
                name: `${m.countryCode} / ${m.name} (${m.region})`
            }))
        );
    }

    get regionDataSource(): LookupEntity<string | null>[] {
        return getSelectDataSource(
            this.regions.map((r) => ({
                id: r,
                name: r
            }))
        );
    }

    get userRestrictionPreset(): ReturnType<
        typeof caseFilterService.getUserRestrictionPreset
    > {
        return caseFilterService.getUserRestrictionPreset(
            this.info.sources,
            this.codeLevelData?.codeLevelBrands ?? [],
            this.codeLevelData?.codeLevelAreas ?? [],
            this.markets
        );
    }

    getCodeLevelName(a: CodeLevelLookupEntity): string {
        return `${a.name}${a.isOutdated ? " (Outdated)" : ""}`;
    }

    filterChangeState(isFilterActive: boolean): void {
        isFilterActive
            ? window.addEventListener("keyup", this.handlePressEnter)
            : window.removeEventListener("keyup", this.handlePressEnter);
    }

    handlePressEnter(event: KeyboardEvent): void {
        if (event.key === "Enter") this.applyFilter();
    }

    async created(): Promise<void> {
        await Promise.all([
            this.loadInfo(),
            this.loadMarkets(),
            this.loadRegions(),
            this.loadCodeLevels()
        ]);

        this.setFiltersFromQuery(this.$route.query);

        if (!this.arePresetsApplied) {
            this.applyStandardFilterPreset();
            this.applyDetailFilterPreset();
            this.applyThemeFilterPreset();

            this.setArePresetsApplied(true);
        }

        this.isDetailFilterOpened = Object.values(this.detailFilter).some(
            (v) => !!v
        );
        this.isThemeFilterOpened = Object.values(this.themeFilter).some(
            (v) => !!v
        );

        this.applyFilter();
    }

    destroyed(): void {
        window.removeEventListener("keyup", this.handlePressEnter);
    }

    private fillStatusesWithCombinedStatus(
        lookupStatuses: LookupEntity<unknown>[],
        infoStatuses: LookupEntityWithOrder<number>[]
    ): void {
        // Add here new combined statuses

        // New + Reopened
        const newStatus = infoStatuses.find(
            (s) => s.name.toUpperCase() === caseStatuses.NEW.toUpperCase()
        );
        const reopenedStatus = infoStatuses.find(
            (s) => s.name.toUpperCase() === caseStatuses.REOPENED.toUpperCase()
        );
        if (newStatus && reopenedStatus) {
            lookupStatuses.push({
                id: [newStatus.id, reopenedStatus.id],
                name: "New & Reopened"
            });
        }
    }

    async exportCases(): Promise<void> {
        const caseFilter = getCaseFilterFromQuery(this.$route.query);
        // At the moment, our production is limited in memory, and we need
        // to artificially limit the number of exported cases.
        // The quantity specified in the backend config will be exported.
        try {
            await makeRequest(
                () => caseApi.checkExportConditions(caseFilter),
                (loading) => (this.isExportLoading = loading)
            );
        } finally {
            const caseExportBlob = await makeRequest(
                () => caseApi.getCaseExportBlob(caseFilter),
                (loading) => (this.isExportLoading = loading)
            );
            downloadUtils.download(caseExportBlob, "trackinglist.xlsx");
        }
    }

    protected resetToDefault(): void {
        this.onStandardFilterChange(false);
        this.onDetailFilterChange(false);
        this.onThemeFilterChange(false);
    }

    @Watch("isStandardFilterOpened")
    protected onStandardFilterChange(value: boolean): void {
        if (!value) {
            const filter = this.standardFilter as Record<string, unknown>;
            Object.keys(filter).forEach((k) => (filter[k] = undefined));
            this.applyStandardFilterPreset();
        }
    }

    protected applyStandardFilterPreset(): void {
        this.standardFilter.searchType = FullTextSearchType.Phrase;

        if (
            isUserInRoles([
                roles.admin,
                roles.superUserInternational,
                roles.agentInternational
            ])
        ) {
            this.filterStatusValue =
                this.statuses.find((s) => s.name === this.newReopenedStatus)
                    ?.id ?? undefined;
        }
    }

    @Watch("isDetailFilterOpened")
    protected onDetailFilterChange(value: boolean): void {
        if (!value) {
            const filter = this.detailFilter as Record<string, unknown>;
            Object.keys(filter).forEach((k) => (filter[k] = undefined));
            this.applyDetailFilterPreset();
        }
    }

    protected applyDetailFilterPreset(): void {
        const { sourceId, marketId, isAllCasesExceptGerman } =
            this.detailFilter;

        if (!sourceId)
            this.detailFilter.sourceId = this.userRestrictionPreset.sourceId;

        if (!marketId)
            this.detailFilter.marketId = this.userRestrictionPreset.marketId;

        if (isAllCasesExceptGerman !== true)
            this.detailFilter.isAllCasesExceptGerman =
                this.userRestrictionPreset.isAllCasesExceptGerman;
    }

    @Watch("isThemeFilterOpened")
    protected onThemeFilterChange(value: boolean): void {
        if (!value) {
            const filter = this.themeFilter as Record<string, unknown>;
            Object.keys(filter).forEach((k) => (filter[k] = undefined));
            this.applyThemeFilterPreset();
        }
    }

    protected applyThemeFilterPreset(): void {
        const { codeLevelBrandId, codeLevelAreaId } = this.themeFilter;

        if (!codeLevelBrandId)
            this.themeFilter.codeLevelBrandId =
                this.userRestrictionPreset.codeLevelBrandId;

        if (!codeLevelAreaId)
            this.themeFilter.codeLevelAreaId =
                this.userRestrictionPreset.codeLevelAreaId;
    }

    @Watch("themeFilter.codeLevelAreaId")
    protected onCodeLevelAreaIdChange(): void {
        this.themeFilter.codeLevelTopicGroupId = undefined;
    }

    @Watch("themeFilter.codeLevelTopicGroupId")
    protected onCodeLevelTopicGroupIdChange(): void {
        this.themeFilter.codeLevelTopicDetailId = undefined;
    }

    async applyFilter(): Promise<void> {
        queryService.setValues(this.$route.query, {
            page: 1,
            ...this.standardFilter,
            ...this.detailFilter,
            ...this.themeFilter
        });
    }

    setFiltersFromQuery(query: Route["query"]): void {
        this.standardFilter = queryService.getValues(
            query,
            standardFilterTypeValues
        );
        this.detailFilter = queryService.getValues(
            query,
            detailFilterTypeValues
        );
        this.themeFilter = queryService.getValues(query, themeFilterTypeValues);
    }
}
