
import { CaseEvent } from "@/models/cases/CaseEvent";
import { VuetifyForm } from "@/models/VuetifyForm";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { CaseEventEditDetails } from "@/models/cases/CaseEventEditDetails";
import BaseButton from "@/components/BaseButton";

@Component({
    components: {
        BaseButton
    }
})
export default class CaseEventEditor extends Vue {
    @Prop({ type: Object, required: false, default: () => ({}) })
    public readonly caseEvent!: Partial<CaseEvent>;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly alwaysDetailed!: boolean;

    @Prop({ type: String, default: "Send" })
    public readonly submitText!: string;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: string;

    private fileInputRules = [
        (files: File[]) =>
            !files?.length ||
            files.map((f) => f.size).reduce((f1, f2) => f1 + f2, 0) <
                134_217_728 ||
            "Total files size should be less than 128 MB"
    ];

    protected title = "";
    protected content = "";
    protected files: File[] = [];

    $refs!: {
        form: VuetifyForm;
    };

    protected showDetailed = false;

    created(): void {
        this.title = this.caseEvent?.title ?? "";
        this.content = this.caseEvent?.content ?? "";
        this.files = [];
    }

    clickOutside(): void {
        this.showDetailed = false;
    }

    submit(): void {
        const result = this.$refs.form.validate();
        if (result) {
            const details: CaseEventEditDetails = {
                title: this.title,
                content: this.content,
                files: this.files
            };
            this.$emit("save", details, this.clear);
        }
    }

    cancel(): void {
        this.$emit("cancel");
        this.clear();
    }

    clear(): void {
        this.title = "";
        this.content = "";
        this.files = [];
        this.showDetailed = false;
    }
}
