import { render, staticRenderFns } from "./CaseInfoEditor.html?vue&type=template&id=b782f12e&"
import script from "./CaseInfoEditor.vue?vue&type=script&lang=ts&"
export * from "./CaseInfoEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports