
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import CaseInfoEditor from "@/components/CaseInfoEditor";
import DynamicTitle from "@/components/DynamicTitle";
import ContactReference from "@/components/ContactReference";
import CodeLevelSetContainer from "@/components/CodeLevelSetContainer";
import CaseEventList from "@/components/CaseEventList";
import BoardCaseForm from "@/components/BoardCaseForm";
import EmailsInBlacklistSnackbar from "@/components/EmailsInBlacklistSnackbar";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { caseModule } from "@/store/modules/case";
import { module as casesModule } from "@/store/modules/cases";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { Routes } from "@/router/routes";
import { Case } from "@/models/cases/Case";
import { Contact } from "@/models/cases/Contact";
import { contactModule } from "@/store/modules/contact";
import { ContactGetters } from "@/store/modules/contact/getters";
import { ContactMutation } from "@/store/modules/contact/mutations";
import { VuetifyForm } from "@/models/VuetifyForm";
import { BoardCaseData } from "@/models/cases/BoardCase";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { cloneDeep, first, isEqual } from "lodash";
import contactUtils from "@/services/contactUtils";
import { BoardCaseSource } from "@/models/cases/BoardCaseSource";
import { ParentCaseLink } from "@/models/cases/ParentCaseLink";

import ContactCarContainer from "@/components/ContactCarContainer";
import DuplicateDialog from "@/components/DuplicateDialog";
import IntegrateDialog from "@/components/IntegrateDialog";
import CaseLinkDialog from "@/components/CaseLinkDialog";
import SpamDialog from "@/components/SpamDialog";
import CloseRelatedCasesDialog from "@/components/CloseRelatedCasesDialog";
import { STOP_REMINDER_PERMISSION } from "@/consts/customClaims";
import {
    isAdmin,
    isUserInRoleTypes,
    userHasClaims
} from "@/services/userUtils";
import { accountModule } from "@/store/modules/account";
import { AccountGetters } from "@/store/modules/account/getters";
import { Session } from "@/models/Session";
import { CasesGetters } from "@/store/modules/cases/getters";
import { CasesActions } from "@/store/modules/cases/actions";
import CaseUpdate from "@/models/cases/CaseUpdate";
import { routeHelpers } from "@/store/modules/route";
import { RouteGetters } from "@/store/modules/route/getters";
import { Route } from "vue-router";
import * as caseSources from "@/consts/caseSources";
import * as caseStatuses from "@/consts/caseStatuses";
import { CodeLevelData } from "@/models/CodeLevelData";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import { CodeLevelActions } from "@/store/modules/codeLevel/actions";
import userRoleValidatorService from "@/services/userRoleValidatorService";

@Component({
    components: {
        CaseInfoEditor,
        DynamicTitle,
        ContactReference,
        CodeLevelSetContainer,
        CaseEventList,
        BoardCaseForm,
        DuplicateDialog,
        CaseLinkDialog,
        IntegrateDialog,
        SpamDialog,
        ContactCarContainer,
        EmailsInBlacklistSnackbar,
        CloseRelatedCasesDialog
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo,
            unlockCase: CasesActions.Unlock
        }),
        ...caseModule.mapActions({
            loadCaseById: CaseActions.Load,
            updateCase: CaseActions.Update,
            stopReminder: CaseActions.StopReminder,
            lock: CaseActions.Lock,
            unlock: CaseActions.Unlock
        }),
        ...contactModule.mapMutations({
            setContact: ContactMutation.SetContact
        }),
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets
        }),
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        }),
        ...codeLevelModule.mapActions({
            loadCodeLevels: CodeLevelActions.LoadCodeLevels
        })
    },
    computed: {
        ...casesModule.mapGetters({
            info: CasesGetters.Info
        }),
        ...caseModule.mapGetters({
            loading: CaseGetters.IsLoading,
            originalCase: CaseGetters.Case
        }),
        ...contactModule.mapGetters({
            contact: ContactGetters.Contact
        }),
        ...accountModule.mapGetters({
            session: AccountGetters.Session
        }),
        ...routeHelpers.mapGetters({
            previousRoute: RouteGetters.PreviousRoute
        }),
        ...codeLevelModule.mapGetters({
            codeLevelData: CodeLevelGetters.CodeLevelData
        })
    }
})
export default class CaseEdit extends Vue {
    protected readonly routes = Routes;

    protected readonly loadInfo!: () => Promise<void>;
    protected readonly loadMarkets!: () => Promise<void>;

    private readonly loadCaseById!: (caseId: number) => Promise<void>;
    private readonly updateCase!: (originalCase: CaseUpdate) => Promise<void>;
    private readonly setContact!: (contact: Contact | null) => void;
    protected readonly stopReminder!: () => Promise<void>;
    private readonly lock!: () => Promise<void>;
    private readonly unlock!: () => Promise<void>;
    private readonly unlockCase!: (caseId: number) => Promise<void>;
    private readonly loadCodeLevels!: () => Promise<void>;

    private readonly showError!: (error: string | string[]) => void;

    protected readonly loading!: boolean;
    private readonly originalCase!: Case;
    private readonly contact!: Contact | null;
    private readonly info!: CaseInfo;
    private readonly session!: Session | null;
    private readonly previousRoute: Route | undefined;

    protected readonly codeLevelData!: CodeLevelData | null;

    protected readonly titleElements: TitleElement[] = [
        { name: "Edit Case", selected: true }
    ];

    $refs!: {
        form: VuetifyForm;
    };

    protected editingCase: Partial<CaseUpdate> = {};
    protected currentBoardCaseData: Partial<BoardCaseData> = {};

    protected duplicateDialog = false;
    protected integrateDialog = false;
    protected caseLinkDialog = false;
    protected spamDialog = false;
    protected isEmailInBlacklistMessageShowing = false;
    protected isCloseRelatedCasesDialogShowing = false;

    get caseIdFromParam(): number {
        const caseId = parseInt(this.$route.params.id);
        return isNaN(caseId) ? 0 : caseId;
    }

    get isBoardCase(): boolean {
        if (this.info.sources.length === 0) return false;

        const boardSource = first(
            this.info.sources.filter(
                (s) => s.name.toUpperCase() === caseSources.BOARD.toUpperCase()
            )
        );
        if (!boardSource) {
            this.showError("Board editingCase source not found");
            return false;
        }

        return this.editingCase.sourceId === boardSource.id;
    }

    get parentLink(): ParentCaseLink | null {
        // Ensure originalCase is defined before checking its properties.
        if (!this.originalCase) {
            return null;
        }

        // Check if there are children and no parent.
        const hasChildren =
            this.originalCase.children && this.originalCase.children.length > 0;
        const hasNoParent = !this.originalCase.parent;

        if (hasChildren && hasNoParent) {
            // If there are children and no parent, use the custom conversion logic.
            return this.convertOriginalCaseToParentCaseLink();
        }

        // If parent exists, return the parent.
        if (this.originalCase.parent) {
            return this.originalCase.parent;
        }
        // If none of the above conditions, return null (nothing will be shown in CaseLinks part of CaseLinkDialog).
        return null;
    }

    get isReadOnly(): boolean {
        return (
            isUserInRoleTypes(["Reader"]) ||
            this.isLockedByOtherUser ||
            userRoleValidatorService.isLockedByCodeLvlSet(
                this.editingCase,
                this.codeLevelData
            ) ||
            this.editingCase.hasSalesforceCaseId === true
        );
    }

    get isStoppingReminderAllowed(): boolean {
        return userHasClaims([STOP_REMINDER_PERMISSION]);
    }

    get isLockedByOtherUser(): boolean {
        const lockedByUser = this.originalCase.lockedByUser;
        const currentUser = this.session?.User;

        return !!(
            lockedByUser &&
            currentUser &&
            lockedByUser.id !== currentUser.id
        );
    }

    get isLockedByCurrentUser(): boolean {
        return this.isCaseLockedByCurrentUser(this.originalCase);
    }

    get lockedByUserFullName(): string {
        if (!this.originalCase?.lockedByUser) return "";

        const { firstName, lastName } = this.originalCase?.lockedByUser;
        return `${firstName} ${lastName}`;
    }

    get hasParentOrChildren(): boolean {
        return (
            this.originalCase.parentId !== null ||
            this.originalCase.children.length > 0
        );
    }

    get isUserAdmin(): boolean {
        return isAdmin();
    }

    async created(): Promise<void> {
        this.loadInfo();
        this.loadMarkets();
        this.loadCodeLevels();

        const oldOriginalCase = this.originalCase;
        await this.onCaseIdFromParamChange(this.caseIdFromParam);

        const newOriginalCase = this.originalCase;
        // set original case field in case it is not loaded, so watch didn't called
        if (isEqual(oldOriginalCase, newOriginalCase))
            await this.onOriginalCaseChange(newOriginalCase, undefined);
    }

    mounted(): void {
        window.addEventListener("beforeunload", this.unlockBeforePageLeave);
    }

    async beforeDestroy(): Promise<void> {
        await this.unlockBeforePageLeave();
        window.removeEventListener("beforeunload", this.unlockBeforePageLeave);
    }

    async unlockBeforePageLeave(): Promise<void> {
        if (this.isLockedByCurrentUser) await this.unlock();
    }

    setContactToCase(): void {
        if (this.contact) {
            this.editingCase.contactId = this.contact.contactId;
        }
    }

    removeContactFromCase(): void {
        this.editingCase.contactId = undefined;
    }

    convertOriginalCaseToParentCaseLink(): ParentCaseLink {
        return {
            caseId: this.originalCase.caseId,
            internalId: this.originalCase.internalId,
            createdDateTime: new Date(this.originalCase.createdDateTime),
            title: this.originalCase.title,
            status: this.originalCase.status,
            children: this.originalCase.children
        } as ParentCaseLink;
    }

    async save(): Promise<void> {
        await this.submit();
    }

    async saveAndClose(): Promise<void> {
        const isSubmittingDone = await this.submit();
        if (isSubmittingDone)
            await this.$router.push(
                this.previousRoute?.path === Routes.CaseOverview
                    ? this.previousRoute.fullPath
                    : Routes.CaseOverview
            );
    }

    private async submit(): Promise<boolean> {
        if (!this.$refs.form.validate()) return false;

        this.editingCase.boardCaseData = this.isBoardCase
            ? (this.currentBoardCaseData as BoardCaseData)
            : null;

        const previousCaseStatus = this.originalCase.status?.name;
        await this.updateCase(this.editingCase as CaseUpdate);
        if (
            previousCaseStatus !== caseStatuses.CLOSED &&
            this.originalCase.status?.name === caseStatuses.CLOSED &&
            this.hasParentOrChildren
        ) {
            this.isCloseRelatedCasesDialogShowing = true;
            return false;
        }
        return true;
    }

    @Watch("caseIdFromParam")
    async onCaseIdFromParamChange(caseIdFromParam: number): Promise<void> {
        if (caseIdFromParam === 0) {
            await this.$router.push(Routes.CaseOverview);
            return;
        }

        if (caseIdFromParam === this.originalCase.caseId) return;

        await this.loadCaseById(caseIdFromParam);
    }

    @Watch("originalCase")
    async onOriginalCaseChange(
        $case: Case,
        oldCase: Case | undefined
    ): Promise<void> {
        // Call the old case unlock only if the current case is readonly because if not, the lock method calling below will unlock the old case
        if (
            oldCase &&
            this.isCaseLockedByCurrentUser(oldCase) &&
            this.isReadOnly
        ) {
            this.unlockCase(oldCase.caseId);
        }

        if ($case.caseId !== this.caseIdFromParam) {
            const params = {
                id: $case.caseId.toString()
            };

            await this.$router.push({ params });
        }

        this.updateEditingCase($case);
        this.isEmailInBlacklistMessageShowing = true;

        if (!this.isReadOnly) {
            await this.lock();
        }
    }

    @Watch("isReadOnly")
    onReadonlyChange(isReadonly: boolean): void {
        if (isReadonly) this.updateEditingCase(this.originalCase);
    }

    updateEditingCase($case: Case): void {
        this.editingCase = {
            mailbox: $case.mailbox,
            sourceId: $case.sourceId ?? undefined,
            inputChannelId: $case.inputChannelId ?? undefined,
            statusId: $case.statusId,
            escalationLevelId: $case.escalationLevelId,
            socialMediaChannelId: $case.socialMediaChannelId,
            socialMediaAgencyId: $case.socialMediaAgencyId,
            marketId: $case.marketId,
            contactId: $case.contactId,
            receiptDateTime: $case.receiptDateTime,
            firstEditDateTime: $case.firstEditDateTime,
            caseBook: $case.caseBook,
            qualified: $case.qualified,
            technicalCampaignId: $case.technicalCampaignId,
            territoryId: $case.territoryId,
            tsaraNumber: $case.tsaraNumber,
            codeLevelSets: cloneDeep($case.codeLevelSets),
            hasSalesforceCaseId: $case.hasSalesforceCaseId
        };
        this.setContact($case.contact);
        this.currentBoardCaseData = {
            // Set value to mount it
            countableRepeatConcern: false,
            ...cloneDeep($case.boardCaseData)
        };
    }

    @Watch("isBoardCase")
    onIsBoardCaseChange(isBoardCase: boolean): void {
        if (!isBoardCase) return;

        if (
            this.currentBoardCaseData.authorOfConcern === undefined &&
            this.contact
        )
            this.currentBoardCaseData.authorOfConcern =
                contactUtils.getFullName(this.contact);

        if (this.currentBoardCaseData.source === undefined)
            this.currentBoardCaseData.source = BoardCaseSource.ManagementBoard;
    }

    isCaseLockedByCurrentUser($case: Case): boolean {
        const lockedByUser = $case?.lockedByUser;
        const currentUser = this.session?.User;

        return !!(
            lockedByUser &&
            currentUser &&
            lockedByUser.id === currentUser.id
        );
    }
}
