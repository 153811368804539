
import { CaseEvent } from "@/models/cases/CaseEvent";
import { getCaseEventFlow } from "@/services/caseEventUtils";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton";
import CaseEventFlow from "@/models/cases/CaseEventFlow";
import CaseEventFlowDirection from "@/models/cases/CaseEventFlowDirection";
import caseEventFlowDirections from "@/consts/caseEventFlowDirections";

type CaseEventPart = {
    event: CaseEvent;
    width: number;
    time: Date;
    flow: CaseEventFlow;
};

@Component({
    components: {
        BaseButton
    }
})
export default class CaseEventReview extends Vue {
    readonly flowDirections: CaseEventFlowDirection[] = [
        caseEventFlowDirections.GKK,
        caseEventFlowDirections.CUSTOMER,
        caseEventFlowDirections.MARKET
    ];

    @Prop({ type: Array, required: true })
    public readonly caseEvents!: CaseEvent[];

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    get caseEventParts(): CaseEventPart[] {
        if (!this.caseEvents.length) return [];

        if (this.caseEvents.length === 1)
            return [
                {
                    event: this.caseEvents[0],
                    width: 100,
                    time: new Date(this.caseEvents[0].createdAtDatetime),
                    flow: getCaseEventFlow(this.caseEvents[0])
                }
            ];

        const events = this.caseEvents.slice().reverse();

        const result: CaseEventPart[] = [];

        const diff =
            new Date(events[events.length - 1].createdAtDatetime).getTime() -
            new Date(events[0].createdAtDatetime).getTime();
        for (let i = 0; i < events.length - 1; i++) {
            const event = events[i];

            result.push({
                event: event,
                time: new Date(event.createdAtDatetime),
                width:
                    ((new Date(events[i + 1].createdAtDatetime).getTime() -
                        new Date(event.createdAtDatetime).getTime()) /
                        diff) *
                    100,
                flow: getCaseEventFlow(event)
            });
        }
        const lastElement = events[events.length - 1];
        result.push({
            event: lastElement,
            time: new Date(lastElement.createdAtDatetime),
            width: 0,
            flow: getCaseEventFlow(lastElement)
        });

        return result;
    }

    getFormattedDateString(date: Date): string {
        return date.toLocaleString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            second: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        });
    }

    selectEvent(event: CaseEvent): void {
        this.$emit("select", event);
    }
}
