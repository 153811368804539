
import Vue from "vue";
import BaseDialog from "@/components/BaseDialog";
import { Component, ModelSync } from "vue-property-decorator";
import { Case } from "@/models/cases/Case";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { CaseLink } from "@/models/cases/CaseLink";
import * as caseStatuses from "@/consts/caseStatuses";
import * as caseSources from "@/consts/caseSources";

@Component({
    components: {
        BaseDialog
    },
    computed: {
        ...caseModule.mapGetters({
            case: CaseGetters.Case,
            IsLoading: CaseGetters.IsLoading
        })
    },
    methods: {
        ...caseModule.mapActions({
            closeRelated: CaseActions.CloseRelated
        })
    }
})
export default class CloseRelatedCasesDialog extends Vue {
    readonly case!: Case;
    readonly IsLoading!: boolean;

    readonly closeRelated!: () => Promise<void>;

    @ModelSync("value", "input", { type: Boolean, required: true })
    valueSync!: boolean;

    get relatedNotClosedCaseTitles(): string[] {
        const relatedCasesLinks: CaseLink[] = [];

        const { children, parent } = this.case;
        relatedCasesLinks.push(...children);
        if (parent) {
            relatedCasesLinks.push(parent);

            const parentChildrenWithoutCurrentCase = parent.children.filter(
                (l) => l.caseId !== this.case.caseId
            );
            relatedCasesLinks.push(...parentChildrenWithoutCurrentCase);
        }

        return relatedCasesLinks
            .filter((l) => l.status?.name !== caseStatuses.CLOSED)
            .map((l) => l.title ?? "Unknown title");
    }

    get isBoardCase(): boolean {
        return this.case.source?.name === caseSources.BOARD;
    }

    get questionText(): string {
        const mainQuestion = "Do you want to close them";
        const boardCaseAddition =
            "and set the same solution as in the closed board case";

        return this.isBoardCase && this.case.boardCaseData?.solution
            ? `${mainQuestion} ${boardCaseAddition}?`
            : `${mainQuestion}?`;
    }

    async confirmCloseRelated(): Promise<void> {
        await this.closeRelated();
        this.valueSync = false;
    }
}
