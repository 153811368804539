
import Vue from "vue";
import { Component, ModelSync } from "vue-property-decorator";
import BaseDialog from "@/components/BaseDialog";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";

@Component({
    components: { BaseDialog },
    computed: caseModule.mapGetters({ isLoading: CaseGetters.IsLoading }),
    methods: {
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        }),
        ...caseModule.mapActions({
            integrateCase: CaseActions.Integrate
        })
    }
})
export default class IntegrateDialog extends Vue {
    readonly isLoading!: boolean;

    readonly showError!: (error: string | string[]) => void;
    readonly integrateCase!: (targetInternalId: number) => Promise<void>;

    @ModelSync("value", "input", { type: Boolean })
    valueSync!: boolean;

    internalCaseId: number | "" = "";

    async integrate(): Promise<void> {
        if (!this.internalCaseId) {
            this.showError("Case Id is empty.");
            return;
        }

        await this.integrateCase(this.internalCaseId);
        this.valueSync = false;
    }
}
