export const IN_PROGRESS = "In Progress";
export const RESOLVED = "Resolved";
export const RESPONDED = "Responded";
export const REOPENED = "Reopened";
export const NEW = "New";
export const WAITING_FOR_CUSTOMER = "Waiting for Customer";
export const WAITING_FOR_DEALER = "Waiting for Dealer";
export const WAITING_FOR_BMW = "Waiting for BMW";
export const FOLLOW_UP = "Follow-up";
export const REASSIGNED = "Reassigned";
export const CLOSED = "Closed";
