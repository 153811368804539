export enum CaseEventType {
    Import = "Import",
    Comment = "Comment",
    Reply = "Reply",
    Forward = "Forward",
    Market = "Market",
    ForwardEsc = "ForwardEsc",
    ForwardEsc2 = "ForwardEsc2",
    CommentBMWIFM = "CommentBMWIFM"
}
