
import { CaseCodeLevelSet } from "@/models/cases/CaseCodeLevelSet";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelActions } from "@/store/modules/codeLevel/actions";
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import CodeLevelSet from "@/components/CodeLevelSet";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import { CodeLevelData } from "@/models/CodeLevelData";
import draggable from "vuedraggable";

@Component({
    components: { CodeLevelSet, draggable },
    methods: {
        ...codeLevelModule.mapActions({
            loadCodeLevels: CodeLevelActions.LoadCodeLevels
        })
    },
    computed: {
        ...codeLevelModule.mapGetters({
            codeLevelData: CodeLevelGetters.CodeLevelData
        })
    }
})
export default class CodeLevelSetContainer extends Vue {
    private readonly loadCodeLevels!: () => Promise<void>;

    protected readonly codeLevelData!: CodeLevelData | null;

    @PropSync("codeLevels", { type: Array, default: () => [] })
    public currentCodeLevels!: CaseCodeLevelSet[];

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    created(): void {
        this.loadCodeLevels();
    }

    get showNewCodeLevel(): boolean {
        return this.currentCodeLevels.every((c) => c.codeLevelTypeId !== null);
    }

    newCodeLevelSet(typeId: number | null): void {
        if (typeId) {
            this.currentCodeLevels.push({
                caseCodeLevelSetId: 0,
                codeLevelTypeId: typeId,
                codeLevelBrandId: null,
                codeLevelAreaId: null,
                codeLevelTopicGroupId: null,
                codeLevelTopicDetailId: null,
                isPrimary: false,
                order: this.currentCodeLevels.length
            });
        }
    }

    deleteCodeLevelSet(index: number): void {
        this.currentCodeLevels.splice(index, 1);
    }

    dragFinish(): void {
        this.currentCodeLevels.forEach((c, index) => {
            c.isPrimary = index === 0;
            c.order = index;
        });
    }

    @Watch("codeLevels", { immediate: true })
    codeLevelsChanged(newVal: CaseCodeLevelSet[] | null): void {
        if (!newVal || !newVal.length) {
            this.currentCodeLevels = [
                {
                    caseCodeLevelSetId: 0,
                    codeLevelTypeId: null,
                    codeLevelBrandId: null,
                    codeLevelAreaId: null,
                    codeLevelTopicGroupId: null,
                    codeLevelTopicDetailId: null,
                    isPrimary: true,
                    order: 0
                }
            ];
        }
    }
}
