
import Vue from "vue";
import { Component, Model, Prop, Watch } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker";
import { BoardCaseData } from "@/models/cases/BoardCase";
import FieldData from "@/services/fieldData";
import { module as casesModule } from "@/store/modules/cases";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { BoardCaseLanguage } from "@/models/cases/BoardCaseLanguage";
import { BoardCaseSource } from "@/models/cases/BoardCaseSource";
import {
    getSelectDataSource,
    getSelectDataSourceFromEnum,
    getSortedSelectDataSource
} from "@/services/dataSourceUtils";
import { LookupEntityWithOrder } from "@/models/LookupEntityWithOrder";
import { LookupEntity } from "@/models/LookupEntity";
import { CasesGetters } from "@/store/modules/cases/getters";
import { CasesActions } from "@/store/modules/cases/actions";

@Component({
    components: {
        DatePicker
    },
    computed: {
        ...casesModule.mapGetters({
            info: CasesGetters.Info,
            isInfoLoading: CasesGetters.IsLoading
        })
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo
        })
    }
})
export default class BoardCaseForm extends Vue {
    public readonly loadInfo!: () => Promise<void>;

    @Model("input", { type: Object, required: true })
    boardCaseData!: Partial<BoardCaseData>;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    readonly info!: CaseInfo;
    readonly isInfoLoading!: boolean;

    readonly languages = getSelectDataSourceFromEnum(BoardCaseLanguage);
    readonly sources = getSelectDataSourceFromEnum(BoardCaseSource);

    isCountableRepeatConcernDisabled = false;

    get dateOfFinalReply(): string | undefined {
        return this.boardCaseData.dateOfFinalReply ?? undefined;
    }

    set dateOfFinalReply(date: string | undefined) {
        this.boardCaseData.dateOfFinalReply = date;
    }

    get dateRepeatConcern(): string | undefined {
        return this.boardCaseData.dateRepeatConcern ?? undefined;
    }

    set dateRepeatConcern(date: string | undefined) {
        this.boardCaseData.dateRepeatConcern = date;
    }

    get bMWDepartmentItems(): LookupEntity<string | number | null>[] {
        const values: LookupEntityWithOrder[] = this.info.bmwDepartments.map(
            (d) => ({
                id: d.bmwDepartmentId,
                name: d.name,
                sortOrder: d.sortOrder
            })
        );
        return getSortedSelectDataSource(values, true);
    }

    get bMWConcernItems(): LookupEntity<string | number | null>[] {
        const values: LookupEntity[] = this.info.bmwConcerns.map((c) => ({
            id: c.bmwConcernId,
            name: `${c.abbreviation} ${c.name}`
        }));
        return getSelectDataSource(values, false);
    }

    authorOfConcernData = new FieldData("Author of Concern", {
        required: true
    });
    departmentData = new FieldData("Region / Department (BMW Group)", {});
    concernToData = new FieldData("Concern To", {
        required: true
    });
    keyIssueGerman = new FieldData("Key Issue(s) German", {
        required: true
    });
    keyIssueInternational = new FieldData("Key Issue(s) International", {
        required: false
    });
    customerExpectationData = new FieldData("Customer Expectation", {
        required: true
    });

    created(): void {
        this.loadInfo();
    }

    @Watch("boardCaseData.repeatConcern", { immediate: true })
    onRepeatConcernChange(val: boolean): void {
        this.isCountableRepeatConcernDisabled = !val;
        if (!val) this.boardCaseData.countableRepeatConcern = false;
    }

    openInNewWindow(link: string): void {
        window.open(link, "_blank");
    }
}
