
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { ContactCar, Drivetrain, MileageUnit } from "@/models/cases/ContactCar";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelActions } from "@/store/modules/codeLevel/actions";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import { CodeLevelData } from "@/models/CodeLevelData";
import {
    defaultNullPlaceholder,
    getSelectDataSourceFromEnum
} from "@/services/dataSourceUtils";
import validationRules from "@/services/validationRules";
import { MarketActions } from "@/store/modules/market/actions";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { Country } from "@/models/markets/Country";

@Component({
    components: {},
    computed: {
        ...codeLevelModule.mapGetters({
            codeLevelData: CodeLevelGetters.CodeLevelData,
            loading: CodeLevelGetters.IsLoading
        }),
        ...marketModule.mapGetters({
            countries: MarketGetters.Countries
        })
    },
    methods: {
        ...codeLevelModule.mapActions({
            loadCodeLevels: CodeLevelActions.LoadCodeLevels
        }),
        ...marketModule.mapActions({
            loadCountries: MarketActions.LoadCountries
        })
    }
})
export default class ContactCarEditor extends Vue {
    @Prop({ type: Object, required: true })
    public readonly contactCar!: ContactCar;
    @Prop({ type: String, required: true })
    public readonly label!: string;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    private readonly loadCountries!: () => Promise<void>;
    protected readonly loadCodeLevels!: () => Promise<void>;

    protected readonly codeLevelData!: CodeLevelData | null;
    protected readonly loading!: boolean;
    protected readonly countries!: Country[];

    protected readonly vinRules = [
        validationRules.required("Vin"),
        validationRules.length(17, "Vin")
    ];

    protected readonly firstRegistrationsRules = [
        validationRules.regex(
            /^(0[1-9]|1[012])[.](19|20)\d\d/,
            "Date is invalid"
        )
    ];

    protected readonly units = getSelectDataSourceFromEnum(MileageUnit, true);
    protected readonly driveTrains = getSelectDataSourceFromEnum(
        Drivetrain,
        true
    );
    get stringFirstRegistration(): string {
        if (this.contactCar.firstRegistration) {
            const date = new Date(this.contactCar.firstRegistration);
            const month = date.getMonth() + 1;
            const monthStr = month < 10 ? "0" + month : month.toString();
            const year = date.getFullYear();
            return `${monthStr}.${year}`;
        } else return "";
    }
    set stringFirstRegistration(value: string) {
        const matchedValue = /^(0[1-9]|1[012])[.](19|20)\d\d/.test(value);

        if (matchedValue) {
            const parts = value.split(".");
            this.contactCar.firstRegistration = new Date(
                Number(parts[1]),
                Number(parts[0]) - 1
            );
        }
    }
    public get countriesWithNull(): { name: string; code: string | null }[] {
        return [
            { name: defaultNullPlaceholder, code: null },
            ...this.countries
        ];
    }
    created(): void {
        this.loadCodeLevels();
        this.loadCountries();
    }

    save(): void {
        this.$emit("save");
    }

    cancel(): void {
        this.$emit("cancel");
    }
}
