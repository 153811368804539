import { getUserClaimValue } from "@/services/userUtils";
import { EDITABLE_CODE_LEVEL } from "@/consts/customClaims";
import CaseUpdate from "@/models/cases/CaseUpdate";
import { CodeLevelData } from "@/models/CodeLevelData";

class userRoleValidatorService {
    public isLockedByCodeLvlSet(
        editingCase: Partial<CaseUpdate>,
        codeLevelData: CodeLevelData | null
    ): boolean {
        const codeLvlClaims = getUserClaimValue(EDITABLE_CODE_LEVEL);

        if (codeLvlClaims == undefined) return false;
        if (editingCase.codeLevelSets == undefined) return true;

        for (const primaryCodeLvlSet of editingCase.codeLevelSets) {
            if (primaryCodeLvlSet) {
                for (const claim of codeLvlClaims) {
                    let result = true;
                    const array = claim.trim().split(",");
                    const codeLevel1: string | null = array[0] ?? null;
                    const codeLevel2: string | null = array[1] ?? null;
                    const codeLevel3: string | null = array[2] ?? null;

                    const currentArea = codeLevelData?.codeLevelAreas.filter(
                        (x) => x.id == primaryCodeLvlSet.codeLevelAreaId
                    )[0];

                    const currentTopicGroup = currentArea?.topicGroups.filter(
                        (x) => x.id == primaryCodeLvlSet.codeLevelTopicGroupId
                    )[0];

                    const currentTopicDetail =
                        currentTopicGroup?.codeLevelTopicDetails.filter(
                            (x) =>
                                x.id == primaryCodeLvlSet.codeLevelTopicDetailId
                        )[0];

                    if (codeLevel1 != null) {
                        result &&=
                            codeLevel1.trim() == currentArea?.name.trim();
                    }
                    if (codeLevel2 != null) {
                        result &&=
                            codeLevel2.trim() == currentTopicGroup?.name.trim();
                    }
                    if (codeLevel3 != null) {
                        result &&=
                            codeLevel3.trim() ==
                            currentTopicDetail?.name.trim();
                    }
                    if (result) return false;
                }
            }
        }
        return true;
    }
}

export default new userRoleValidatorService();
