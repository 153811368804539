
import Vue from "vue";
import { Component, PropSync, Prop } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton";

@Component({
    components: { BaseButton }
})
export default class IFramePopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: String, required: true })
    public title!: string;

    @Prop({ type: String, required: true })
    public html!: string;

    @Prop({ type: Number, default: 800 })
    public width!: number;

    @Prop({ type: Number, default: 800 })
    public height!: number;
}
