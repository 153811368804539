
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { CaseEvent } from "@/models/cases/CaseEvent";
import { CaseEventType } from "@/models/cases/CaseEventType";
import { isAdmin, getCurrentUser } from "@/services/userUtils";
import CaseEventEditor from "@/components/CaseEventEditor";
import { CaseEventEditDetails } from "@/models/cases/CaseEventEditDetails";
import AttachmentButton from "@/components/AttachmentButton";
import CaseEventFlow from "@/models/cases/CaseEventFlow";
import { getCaseEventFlow } from "@/services/caseEventUtils";

interface EventAction {
    name: string;
    action: () => void;
}

@Component({
    components: {
        CaseEventEditor,
        AttachmentButton
    }
})
export default class CaseEventViewer extends Vue {
    @Prop({ type: Object, required: true })
    public readonly caseEvent!: CaseEvent;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    // Show attachments by default
    protected showAttachments = true;
    protected editMode = false;

    get isHtmlDoc(): boolean {
        // if there is html tag then we should use iframe
        return this.caseEvent.content?.includes("<html") ?? false;
    }

    resizeIframe(event: Event): void {
        const obj = event.target as HTMLIFrameElement;
        if (obj?.contentDocument) {
            obj.style.height = obj.contentDocument?.body.scrollHeight + "px";
        }
    }

    get flow(): CaseEventFlow {
        return getCaseEventFlow(this.caseEvent);
    }

    get caseEventSender(): string | undefined {
        let result: undefined | string = undefined;
        const caseEventType = this.caseEvent.caseEventType.name;

        if (caseEventType === CaseEventType.Market) {
            if (this.caseEvent.handedFromMarket?.countryCode) {
                result = this.caseEvent.handedFromMarket.countryCode;
            } else {
                result = this.caseEvent.emailFromName;
            }
        } else if (this.caseEvent.createdByUser) {
            result = `${this.caseEvent.createdByUser.firstName} ${this.caseEvent.createdByUser.lastName}`;
        }

        if (!result && this.caseEvent.emailFromAddress) {
            if (this.caseEvent.emailFromName) {
                result = `${this.caseEvent.emailFromName} ${this.caseEvent.emailFromAddress}`;
            } else {
                result = this.caseEvent.emailFromAddress;
            }
        }

        return result;
    }

    get caseEventReceiver(): string | undefined {
        let result: undefined | string = undefined;
        const caseEventType = this.caseEvent.caseEventType.name;

        if (
            caseEventType === CaseEventType.Forward &&
            this.caseEvent.handedToMarket
        ) {
            result = `${this.caseEvent.handedToMarket.countryCode} ${
                this.caseEvent.handedToMarket.name
            } | ${
                this.caseEvent.emailTo
                    ? this.caseEvent.emailTo.split(";").join(", ")
                    : ""
            }`;
        }

        if (!result && this.caseEvent.emailTo) {
            result = this.caseEvent.emailTo.split(";").join(", ");
        }

        return result;
    }

    get caseEventCC(): string | undefined {
        if (this.caseEvent.emailCc) {
            return this.caseEvent.emailCc.split(";").join(", ");
        }
        return "";
    }

    get formattedTime(): string {
        return new Date(this.caseEvent.createdAtDatetime).toLocaleTimeString(
            [],
            { hour: "2-digit", minute: "2-digit" }
        );
    }

    get actions(): EventAction[] {
        const result: EventAction[] = [];

        if (this.readOnly) return result;

        const caseEventType = this.caseEvent.caseEventType
            .name as CaseEventType;

        if (
            [CaseEventType.Import, CaseEventType.Market].includes(
                caseEventType
            ) &&
            !this.readOnly
        ) {
            result.push({
                name: "Reply",
                action: this.replyCaseEvent
            });
            result.push({
                name: "Forward",
                action: this.forwardCaseEvent
            });
            result.push({
                name: "Convert to new case",
                action: this.convertToNewCase
            });
        }

        if (
            isAdmin() ||
            (this.caseEvent.createdBy &&
                getCurrentUser()?.id === this.caseEvent.createdBy)
        ) {
            result.push({
                name: "Edit",
                action: () => (this.editMode = true)
            });
            result.push({
                name: "Delete",
                action: this.deleteCaseEvent
            });
        }

        return result;
    }

    convertToNewCase(): void {
        this.$emit("convert-to-new-case", this.caseEvent.caseEventId);
    }

    forwardCaseEvent(): void {
        this.$emit("forward", this.caseEvent.caseEventId);
    }

    replyCaseEvent(): void {
        this.$emit("reply", this.caseEvent.caseEventId);
    }

    deleteCaseEvent(): void {
        this.$emit("delete", this.caseEvent.caseEventId);
    }

    editComment(details: CaseEventEditDetails, close: () => void): void {
        this.$emit("edit", this.caseEvent.caseEventId, details, () => {
            this.editMode = false;
            close();
        });
    }
}
