import { Rule } from "@/models/rules/Rule";
import { RuleType } from "@/models/rules/RuleType";
import { EmailRuleContent } from "@/models/rules/contents/EmailRuleContent";
import { RuleContent } from "@/models/rules/contents";
import { ForwardRuleContent } from "@/models/rules/contents/ForwardRuleContent";
import { AddToSubjectRuleContent } from "@/models/rules/contents/AddToSubjectRuleContent";
import { FormatRuleContent } from "@/models/rules/contents/FormatRuleContent";
import { ConditionRuleContent } from "@/models/rules/contents/ConditionRuleContent";

class RuleHelper {
    public getRecommendedPriorityForRule(rule: Partial<Rule>): number {
        let result = 0;

        // Calculation was taken from the old solution
        if (rule.caseInputChannelId !== null) {
            result += 5;
        }
        if (rule.caseSourceId !== null) {
            result += 11;
        }
        if (rule.codeLevelTypeId !== null) {
            result += 21;
        }
        if (rule.codeLevelBrandId !== null) {
            result += 31;
        }
        if (rule.codeLevelAreaId !== null) {
            result += 41;
        }
        if (rule.codeLevelTopicGroupId !== null) {
            result += 45;
        }
        if (rule.codeLevelTopicDetailId !== null) {
            result += 51;
        }
        if (
            rule.caseInputChannelId === null &&
            rule.caseSourceId === null &&
            rule.codeLevelTypeId === null &&
            rule.codeLevelBrandId === null &&
            rule.codeLevelAreaId === null &&
            rule.codeLevelTopicGroupId === null &&
            rule.codeLevelTopicDetailId === null
        ) {
            result = 1000;
        }

        return result;
    }
    getRuleType(rule: Rule): RuleType {
        return rule.ruleType?.name as RuleType;
    }
    getRuleDescription(rule: Rule): string {
        const type = this.getRuleType(rule);
        let content: RuleContent;
        switch (type) {
            case RuleType.AddEmail:
                content = rule.content as EmailRuleContent;
                return `Add this email when forwarding. TO: ${content.sendTo}; CC: ${content.sendCc}; BCC: ${content.sendBcc}`;
            case RuleType.Email:
                content = rule.content as EmailRuleContent;
                return `Email for first forward and first automatic forward. TO: ${content.sendTo}; CC: ${content.sendCc}; BCC: ${content.sendBcc}`;
            case RuleType.EmailEsc:
                content = rule.content as EmailRuleContent;
                return `Email for second automatic forward. TO: ${content.sendTo}; CC: ${content.sendCc}; BCC: ${content.sendBcc}`;
            case RuleType.EmailEsc2:
                content = rule.content as EmailRuleContent;
                return `Email for third automatic forward. TO: ${content.sendTo}; CC: ${content.sendCc}; BCC: ${content.sendBcc}`;
            case RuleType.Forward:
                content = rule.content as ForwardRuleContent;
                return `Remind Process ${
                    content.reminderProcess ? "possible" : "NOT possible"
                }`;
            case RuleType.AddToSubject:
                content = rule.content as AddToSubjectRuleContent;
                return `Add ${content.string} at the ${content.where} of the email subject.`;
            case RuleType.Title:
                content = rule.content as FormatRuleContent;
                return `Case Title format: ${content.format.join(" ")}`;
            case RuleType.Subject:
                content = rule.content as FormatRuleContent;
                return `Case Subject format: ${content.format.join(" ")}`;
            case RuleType.Condition:
                // eslint-disable-next-line no-case-declarations
                let result = "";
                content = rule.content as ConditionRuleContent;
                (content as ConditionRuleContent).conditions.forEach((c) => {
                    result += `${c} is mandatory;`;
                });
                if (content.sendTo) {
                    content.sendTo.forEach((e) => {
                        result += `IF RECIPIENT IS: ${e}`;
                    });
                }
                return result;
            default:
                return "";
        }
    }
}

export default new RuleHelper();
