
import { Case } from "@/models/cases/Case";
import Vue from "vue";
import Component from "vue-class-component";
import CaseInfoEditor from "@/components/CaseInfoEditor";
import DynamicTitle from "@/components/DynamicTitle";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { Routes } from "@/router/routes";
import { module as casesModule } from "@/store/modules/cases";
import { VuetifyForm } from "@/models/VuetifyForm";
import { isUserInRoles } from "@/services/userUtils";
import { germanMarketRoles } from "@/consts/roles";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { mailboxes } from "@/consts/mailBoxes";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { Market } from "@/models/markets/Market";
import { CasesActions } from "@/store/modules/cases/actions";
import { CasesGetters } from "@/store/modules/cases/getters";
import { caseModule } from "@/store/modules/case";
import { CaseMutation } from "@/store/modules/case/mutations";
import * as caseSources from "@/consts/caseSources";

@Component({
    components: { CaseInfoEditor, DynamicTitle },
    methods: {
        ...caseModule.mapMutations({
            setCase: CaseMutation.SetCase
        }),
        ...casesModule.mapActions({
            createCase: CasesActions.CreateCase,
            loadInfo: CasesActions.LoadInfo
        })
    },
    computed: {
        ...casesModule.mapGetters({
            loading: CasesGetters.IsLoading,
            info: CasesGetters.Info
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    }
})
export default class CaseCreate extends Vue {
    protected caseModel: Partial<Case> = {};

    $refs!: {
        form: VuetifyForm;
    };

    protected readonly loading!: boolean;
    protected readonly label = "Create Case";
    protected readonly info!: CaseInfo;
    protected readonly markets!: Market[];

    private readonly setCase!: (caseModel: Case) => void;
    private readonly createCase!: (caseModel: Partial<Case>) => Promise<Case>;
    private readonly loadInfo!: () => Promise<void>;

    protected get titleElements(): TitleElement[] {
        return [{ name: this.label, selected: true }];
    }

    async created(): Promise<void> {
        await this.loadInfo();

        if (isUserInRoles(germanMarketRoles)) {
            this.setSource(caseSources.BOARD);
            this.setMailbox(mailboxes.germanMailbox);
            this.setMarket("C1-DE");

            this.caseModel = { ...this.caseModel };
        }
    }

    setSource(sourceName: string): void {
        const boardSource = this.info.sources.find(
            (s) => s.name.toUpperCase() === sourceName.toUpperCase()
        );
        if (boardSource) this.caseModel.sourceId = boardSource.id;
    }

    setMailbox(mailbox: string): void {
        const infoMailbox = this.info.mailboxes.find(
            (m) => m.email.toUpperCase() === mailbox.toUpperCase()
        );
        if (infoMailbox) this.caseModel.mailbox = infoMailbox.email;
    }

    setMarket(region: string): void {
        const market = this.markets.find(
            (m) => m.region.toUpperCase() === region.toUpperCase()
        );
        if (market) this.caseModel.marketId = market.marketId;
    }

    async submit(): Promise<void> {
        if (this.$refs.form.validate()) {
            this.caseModel.children = [];
            this.caseModel.codeLevelSets = [];
            const createdCase = await this.createCase(this.caseModel);
            this.setCase(createdCase);

            this.$router.push(Routes.CaseEdit + createdCase.caseId);
        }
    }
}
