
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class AttachmentButton extends Vue {
    @Prop(String)
    readonly filePath!: string | undefined;

    @Prop(String)
    readonly fileName!: string | undefined;
}
