
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import DatePicker from "@/components/DatePicker";
import ContactCarEditor from "@/components/ContactCarEditor";
import { contactModule } from "@/store/modules/contact";
import { ContactActions } from "@/store/modules/contact/actions";
import { ContactCar } from "@/models/cases/ContactCar";
import _ from "lodash";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: { DatePicker, ConfirmationDialog, ContactCarEditor },
    methods: {
        ...contactModule.mapActions({
            createContactCar: ContactActions.CreateContactCar,
            updateContactCar: ContactActions.UpdateContactCar,
            deleteContactCar: ContactActions.DeleteContactCar
        }),
        ...contactModule.mapMutations({}),
        ...contactModule.mapGetters({})
    }
})
export default class ContactCarContainer extends Vue {
    @Prop({ type: Array, required: true })
    public readonly cars!: Partial<ContactCar>[];
    @Prop({ type: Number, required: true })
    public readonly contactId!: number;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    $refs!: {
        form: VuetifyForm;
    };

    protected headers = [
        { text: "Vin", value: "vin" },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];
    private selectedCar: ContactCar | null = null;
    private newCar: Omit<Partial<ContactCar>, "contactCarId"> = {};
    private deleteContactCarId = 0;
    private showAdd = false;
    private readonly createContactCar!: (payload: {
        contactId: number;
        contactCar: Omit<Partial<ContactCar>, "contactCarId">;
    }) => Promise<number>;
    private readonly updateContactCar!: (payload: {
        contactId: number;
        contactCar: ContactCar;
    }) => Promise<void>;
    private readonly deleteContactCar!: (payload: {
        contactId: number;
        contactCarId: number;
    }) => Promise<void>;
    private deleteDialog = false;

    get currentContactCar(): ContactCar | null {
        if (this.selectedCar) {
            return { ...this.selectedCar };
        }
        return null;
    }

    cancel(): void {
        this.showAdd = false;
        this.newCar = {};
        this.selectedCar = null;
    }

    setSelectCar(vehicle: ContactCar): void {
        this.cancel();
        this.selectedCar = vehicle;
    }

    deleteVehicle(vehicle: ContactCar): void {
        this.deleteContactCarId = vehicle.contactCarId;
        this.deleteDialog = true;
    }

    submit(): void {
        const result = this.$refs.form.validate();
        if (!result) return;

        if (this.currentContactCar) {
            this.updateContactCar({
                contactId: this.contactId,
                contactCar: this.currentContactCar
            }).then(() => this.cancel());
        } else {
            this.showAdd = true;
            if (!_.isEmpty(this.newCar)) {
                this.createContactCar({
                    contactId: this.contactId,
                    contactCar: this.newCar
                }).then(() => this.cancel());
            }
        }
    }

    confirmDeleteVehicle(): void {
        this.deleteContactCar({
            contactId: this.contactId,
            contactCarId: this.deleteContactCarId
        }).then(() => (this.deleteDialog = false));
    }
}
