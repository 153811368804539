
import { Contact } from "@/models/cases/Contact";
import { contactModule } from "@/store/modules/contact";
import { ContactActions } from "@/store/modules/contact/actions";
import { ContactGetters } from "@/store/modules/contact/getters";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    computed: {
        ...contactModule.mapGetters({
            loading: ContactGetters.IsLoading,
            contacts: ContactGetters.Contacts
        })
    },
    methods: {
        ...contactModule.mapActions({
            searchContacts: ContactActions.SearchContacts
        })
    }
})
export default class ContactSearchForm extends Vue {
    private readonly searchContacts!: (payload: {
        firstName: string;
        lastName: string;
        email: string;
    }) => Promise<void>;
    protected readonly contacts!: Contact[];
    protected readonly loading!: boolean;

    protected email = "";
    protected firstName = "";
    protected lastName = "";

    search(): void {
        this.searchContacts({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email
        });
    }

    select(contact: Contact): void {
        this.$emit("select-contact", contact);
    }
}
