
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { module as casesModule } from "@/store/modules/cases";
import { MailboxInfo } from "@/models/MailboxInfo";
import { isUserInRoles } from "@/services/userUtils";
import { germanMarketRoles } from "@/consts/roles";
import { mailboxes } from "@/consts/mailBoxes";
import queryService from "@/services/queryService";
import { CasesGetters } from "@/store/modules/cases/getters";
import { CasesActions } from "@/store/modules/cases/actions";

@Component({
    computed: {
        ...casesModule.mapGetters({
            info: CasesGetters.Info,
            isLoading: CasesGetters.IsLoading
        })
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo
        })
    }
})
export default class MailboxFilter extends Vue {
    protected selectedMailboxes: string[] = [];

    protected readonly info!: CaseInfo;
    protected readonly isLoading!: boolean;
    protected readonly loadInfo!: () => Promise<void>;

    get mailboxes(): MailboxInfo[] {
        let mailboxesToSet;
        if (isUserInRoles(germanMarketRoles)) {
            mailboxesToSet = this.info.mailboxes.filter(
                (m) => m.email.toLowerCase() === mailboxes.germanMailbox
            );
        } else {
            mailboxesToSet = this.info.mailboxes;
        }

        return mailboxesToSet;
    }

    async created(): Promise<void> {
        await this.loadInfo();
    }

    applyMailboxes(mailboxes: string[]): void {
        queryService.setValues(this.$route.query, {
            page: 1,
            mailboxes
        });
    }

    @Watch("$route.query", { immediate: true })
    async onQueryChange(): Promise<void> {
        const queryValues = queryService.getValues(this.$route.query, {
            mailboxes: [""]
        });

        this.selectedMailboxes = queryValues.mailboxes ?? [];
    }
}
