
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import DynamicTitle from "@/components/DynamicTitle";
import { module as casesModule } from "@/store/modules/cases";
import { CasesActions } from "@/store/modules/cases/actions";
import { SortParams } from "@/models/cases/CaseFilter";
import { PaginationInfo } from "@/models/PaginationInfo";
import { DataOptions } from "vuetify";
import queryService from "@/services/queryService";
import { Route } from "vue-router";
import { getCaseFilterFromQuery } from "@/services/queryFactoryUtil";
import { CasesGetters } from "@/store/modules/cases/getters";
import { PaginationResult } from "@/models/PaginationResult";
import { CaseListItem } from "@/models/cases/CaseListItem";
import CaseList from "@/components/CaseList";
import { Routes } from "@/router/routes";
import { CasesMutation } from "@/store/modules/cases/mutations";

@Component({
    components: {
        DynamicTitle,
        CaseList
    },
    methods: {
        ...casesModule.mapActions({
            loadFilteredFavoriteCasesWithPagination:
                CasesActions.LoadFilteredFavoriteCasesWithPagination,
            markFavorite: CasesActions.MarkCaseAsFavorite,
            unmarkFavorite: CasesActions.UnmarkCaseAsFavorite
        }),
        ...casesModule.mapMutations({
            setFavoriteCases: CasesMutation.SetFavoriteCases
        })
    },
    computed: {
        ...casesModule.mapGetters({
            paginationData: CasesGetters.PaginationData,
            isLoading: CasesGetters.IsLoading,
            favoriteCases: CasesGetters.FavoriteCases
        })
    }
})
export default class Favorites extends Vue {
    private readonly loadFilteredFavoriteCasesWithPagination!: (payload: {
        paginationInfo: PaginationInfo;
        sortParams?: SortParams;
    }) => Promise<void>;
    private readonly setFavoriteCases!: (payload: number[]) => void;
    protected readonly markFavorite!: (caseId: number) => Promise<void>;
    protected readonly unmarkFavorite!: (caseId: number) => Promise<void>;

    protected readonly paginationData!: PaginationResult<CaseListItem>;
    protected readonly isLoading!: boolean;
    protected readonly favoriteCases!: number[];

    get options(): DataOptions {
        const queryValues = queryService.getValues(this.$route.query, {
            page: 0,
            itemsPerPage: 0,
            sortBy: [""],
            sortDesc: [false]
        });

        return {
            page: queryValues.page ?? 0,
            itemsPerPage: queryValues.itemsPerPage ?? 0,
            sortBy: queryValues.sortBy ?? [],
            sortDesc: queryValues.sortDesc ?? [],
            multiSort: false,
            mustSort: false,
            groupBy: [],
            groupDesc: []
        };
    }

    set options({ page, itemsPerPage, sortBy, sortDesc }: DataOptions) {
        queryService.setValues(this.$route.query, {
            page,
            itemsPerPage,
            sortBy,
            sortDesc
        });
    }

    async created(): Promise<void> {
        // We need to check if the options were updated or not
        if (!(await this.setupDefaultOptions())) {
            await this.loadFavoriteCases(this.$route.query);
        }
    }

    editItem(caseId: number | null): void {
        if (caseId) {
            this.$router.push(Routes.CaseEdit + caseId);
        }
    }

    private async setupDefaultOptions(): Promise<boolean> {
        const { page, itemsPerPage } = this.options;

        if (!page || !itemsPerPage) {
            await queryService.setValues(this.$route.query, {
                page: 1,
                itemsPerPage: 20
            });
            return true;
        }
        return false;
    }

    @Watch("$route.query")
    async onQueryChange(query: Route["query"]): Promise<void> {
        await this.loadFavoriteCases(query);
    }

    private async loadFavoriteCases(query: Route["query"]) {
        const sortParams = getCaseFilterFromQuery(query).sortBy;
        await this.loadFilteredFavoriteCasesWithPagination({
            paginationInfo: {
                skip: (this.options.page - 1) * this.options.itemsPerPage,
                take: this.options.itemsPerPage
            },
            sortParams
        });
        this.setFavoriteCases(this.paginationData.items.map((c) => c.caseId));
    }
}
