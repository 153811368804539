
import Vue from "vue";
import { Component, ModelSync, Prop } from "vue-property-decorator";
import BaseDialog from "@/components/BaseDialog";
import caseApi from "@/api/caseApi";
import { CaseParentCandidate } from "@/models/cases/CaseParentCandidate";
import CaseLinks from "@/components/CaseLinks";
import { CaseLink } from "@/models/cases/CaseLink";
import { Routes } from "@/router/routes";
import { ParentCaseLink } from "@/models/cases/ParentCaseLink";
import { makeRequest } from "@/services/requestUtils";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { Case } from "@/models/cases/Case";

@Component({
    components: {
        BaseDialog,
        CaseLinks
    },
    computed: {
        ...caseModule.mapGetters({
            $case: CaseGetters.Case,
            isLoading: CaseGetters.IsLoading
        })
    },
    methods: {
        ...notificationsModule.mapActions({
            showError: NotificationsActions.NotifyError
        }),
        ...caseModule.mapActions({
            updateParent: CaseActions.UpdateParent,
            deleteParent: CaseActions.DeleteParent
        })
    }
})
export default class CaseLinkDialog extends Vue {
    readonly inputHeight = 46;

    readonly $case!: Case;
    readonly isLoading!: boolean;
    readonly showError!: (val: string | string[]) => void;
    readonly updateParent!: (payload: number) => Promise<void>;
    readonly deleteParent!: () => Promise<void>;

    @ModelSync("value", "input", { type: Boolean })
    valueSync!: boolean;

    @Prop({ type: Object, default: () => ({}) })
    parentLink?: ParentCaseLink;

    isSearching = false;
    parentCandidates: CaseParentCandidate[] | null = null;
    search = "";
    candidateInternalId: number | "" = "";

    get isParent(): boolean {
        return (
            this.parentLink?.caseId === this.$case.caseId &&
            this.parentLink?.children.length > 0
        );
    }

    async searchCandidatesForParent(): Promise<void> {
        this.parentCandidates = await makeRequest(
            () => caseApi.searchParents(this.search, 10),
            (loading) => (this.isSearching = loading)
        );
    }

    async linkCandidateForParent(): Promise<void> {
        if (!this.candidateInternalId) {
            this.showError("Case Id is empty.");
            return;
        }

        await this.updateParent(this.candidateInternalId);
        this.valueSync = false;
    }

    async deleteParentCase(): Promise<void> {
        await this.deleteParent();
        this.valueSync = false;
    }

    goToLinkCase(link: CaseLink): void {
        if (link.caseId === this.$case.caseId) return;

        this.valueSync = false;
        this.$router.push(Routes.CaseEdit + link.caseId);
    }
}
