
import Vue from "vue";
import { Component, PropSync } from "vue-property-decorator";
import CaseAttachmentSelector from "@/components/CaseAttachmentSelector";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import BaseButton from "@/components/BaseButton";
import { VuetifyForm } from "@/models/VuetifyForm";
import { Case } from "@/models/cases/Case";
import { CaseActions } from "@/store/modules/case/actions";
import { SendEmailData } from "@/models/cases/SendEmailData";

@Component({
    components: {
        CaseAttachmentSelector,
        BaseButton
    },
    methods: {
        ...caseModule.mapActions({
            sendEmailToCase: CaseActions.SendEmail
        })
    },
    computed: {
        ...caseModule.mapGetters({
            case: CaseGetters.Case,
            isLoading: CaseGetters.IsLoading
        })
    }
})
export default class SendEmailPopup extends Vue {
    private readonly sendEmailToCase!: (
        payload: SendEmailData
    ) => Promise<void>;

    protected readonly case!: Case;
    protected readonly isLoading!: boolean;

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    $refs!: {
        form: VuetifyForm;
    };

    protected title = "";
    protected sendTo = "";
    protected sendCc = "";
    protected content = "";
    protected selectedAttachments: number[] = [];

    created(): void {
        if (this.case?.title) {
            this.title = this.case.title;
        }
    }

    async send(): Promise<void> {
        const result = this.$refs.form.validate();
        if (result) {
            await this.sendEmailToCase({
                title: this.title,
                content: this.content,
                sendTo: this.sendTo,
                sendCc: this.sendCc,
                attachments: this.selectedAttachments
            });
            this.popupVisible = false;
        }
    }
}
