const caseEventFlowDirections = {
    CUSTOMER: {
        label: "Customer",
        color: "grey darken-3"
    },
    GKK: {
        label: "GKK",
        color: "blue lighten-3"
    },
    MARKET: {
        label: "Market",
        color: "primary"
    },
    INTERNAL: {
        label: "Internal",
        color: "blue lighten-3"
    }
};

export default caseEventFlowDirections;
