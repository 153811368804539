
import Vue from "vue";
import { Component, ModelSync } from "vue-property-decorator";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { Contact } from "@/models/cases/Contact";
import contactUtils from "@/services/contactUtils";
import { module as casesModule } from "@/store/modules/cases";
import { contactModule } from "@/store/modules/contact";
import { ContactGetters } from "@/store/modules/contact/getters";
import { CasesGetters } from "@/store/modules/cases/getters";
import { CasesActions } from "@/store/modules/cases/actions";

@Component({
    computed: {
        ...contactModule.mapGetters({
            contact: ContactGetters.Contact
        }),
        ...casesModule.mapGetters({
            caseInfo: CasesGetters.Info
        })
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo
        })
    }
})
export default class EmailsInBlacklistSnackbar extends Vue {
    readonly contact!: Contact;
    readonly caseInfo!: CaseInfo;

    readonly loadInfo!: () => Promise<void>;

    @ModelSync("value", "change", { type: Boolean })
    isShowing!: boolean;

    get isShowingDependingOnEmailsInBlacklist(): boolean {
        return this.isShowing && this.emailsInBlacklist.length > 0;
    }
    set isShowingDependingOnEmailsInBlacklist(val: boolean) {
        this.isShowing = val;
    }

    get emailsInBlacklistMessage(): string {
        return `${this.emailsInBlacklist.join(", ")} ${
            this.emailsInBlacklist.length > 1 ? "are" : "is"
        } on the blacklist.`;
    }

    get emailsInBlacklist(): string[] {
        if (!this.contact) return [];

        const contactEmails = contactUtils.getContactEmails(this.contact);
        return contactEmails.filter((e) =>
            this.caseInfo.blacklistEmails.includes(e)
        );
    }

    created(): void {
        this.loadInfo();
    }
}
