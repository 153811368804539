
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import BaseButton from "@/components/BaseButton";
import { VuetifyForm } from "@/models/VuetifyForm";
import { Contact, ContactSalutation } from "@/models/cases/Contact";
import { contactModule } from "@/store/modules/contact";
import { ContactGetters } from "@/store/modules/contact/getters";
import { Case } from "@/models/cases/Case";
import contactUtils from "@/services/contactUtils";
import { CaseActions } from "@/store/modules/case/actions";
import { ReplyEventData } from "@/models/cases/ReplyEventData";

type Language = "english" | "german";

@Component({
    components: {
        BaseButton
    },
    methods: {
        ...caseModule.mapActions({
            replyEvent: CaseActions.ReplyEvent,
            loadReplyTemplate: CaseActions.GetReplyTemplate
        })
    },
    computed: {
        ...caseModule.mapGetters({
            case: CaseGetters.Case,
            isLoading: CaseGetters.IsLoading,
            replyTemplate: CaseGetters.ReplyTemplate
        }),
        ...contactModule.mapGetters({
            contact: ContactGetters.Contact
        })
    }
})
export default class ReplyPopup extends Vue {
    private readonly replyEvent!: (payload: ReplyEventData) => Promise<void>;
    private readonly loadReplyTemplate!: () => Promise<void>;

    protected readonly errors!: string[];
    protected readonly contact!: Contact | null;
    protected readonly case!: Case | null;
    protected readonly isLoading!: boolean;
    protected readonly replyTemplate!: string;

    protected readonly languageItems: { text: string; value: Language }[] = [
        { text: "English salutation", value: "english" },
        { text: "German salutation", value: "german" }
    ];

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: Number, required: true })
    public caseEventId!: number;

    $refs!: {
        form: VuetifyForm;
    };

    protected title = "";
    protected content = "";
    protected sendTo = "";
    protected quotePreviousComment = false;

    async reply(): Promise<void> {
        const result = this.$refs.form.validate();
        if (result) {
            await this.replyEvent({
                title: this.title,
                content: this.content,
                caseEventId: this.caseEventId,
                sendTo: this.sendTo,
                quotePreviousComment: this.quotePreviousComment
            });
            this.popupVisible = false;
        }
    }

    @Watch("visible", { immediate: true })
    onVisibleChanged(visible: boolean): void {
        if (visible) {
            this.content = this.replyTemplate;
            if (this.case?.title) this.title = this.case?.title;

            if (this.contact) {
                const contactEmail = contactUtils.getContactEmails(
                    this.contact
                )[0];
                if (contactEmail) this.sendTo = contactEmail;
            }
        } else {
            this.$refs.form.reset();
            this.quotePreviousComment = false;
        }
    }

    generateSalutationAndAddToContent(language: Language): void {
        let salutation;
        switch (language) {
            case "german": {
                const salutationWords =
                    this.contact?.salutation === ContactSalutation.Mrs
                        ? "geehrte Frau"
                        : "geehrter Herr";
                salutation = `Sehr ${salutationWords} ${this.contact?.lastName},`;
                break;
            }
            case "english":
            default: {
                const salutationWord =
                    this.contact?.salutation ?? ContactSalutation.Mr;
                salutation = `Dear ${salutationWord}. ${this.contact?.lastName},`;
                break;
            }
        }

        this.content = salutation + "\n\n" + this.content;
    }
    async created(): Promise<void> {
        await this.loadReplyTemplate();
        this.content = this.replyTemplate;
    }
}
