
import { CaseCodeLevelSet } from "@/models/cases/CaseCodeLevelSet";
import { CodeLevelData } from "@/models/CodeLevelData";
import { CodeLevelLookupEntity } from "@/models/CodeLevelLookupEntity";
import { LookupEntity } from "@/models/LookupEntity";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

type CodeLevelArea = CodeLevelData["codeLevelAreas"][0];
type CodeLevelTopicGroup = CodeLevelArea["topicGroups"][0];

@Component({
    computed: {
        ...codeLevelModule.mapGetters({
            codeLevelData: CodeLevelGetters.CodeLevelData,
            loading: CodeLevelGetters.IsLoading
        })
    }
})
export default class CodeLevelSet extends Vue {
    protected readonly codeLevelData!: CodeLevelData | null;
    protected readonly loading!: boolean;

    @Prop({ type: Object, required: true })
    public readonly codeLevelSet!: CaseCodeLevelSet;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    get codeLevelBrands(): LookupEntity[] {
        // Not displaying outdated code levels
        const data = this.codeLevelData?.codeLevelBrands.filter(
            (c) => !c.isOutdated
        );

        // If the current code level is outdated it must be added in the item collection to be displayed
        // important for displaying code levels of imported cases
        if (this.selectedCodeLevelBrand?.isOutdated) {
            data?.push(this.selectedCodeLevelBrand);
        }

        return data ?? [];
    }

    get selectedCodeLevelBrand(): CodeLevelLookupEntity | null {
        return (
            this.codeLevelData?.codeLevelBrands.find(
                (t) => t.id === this.codeLevelSet.codeLevelBrandId
            ) ?? null
        );
    }

    get codeLevelTypes(): LookupEntity[] {
        // Not displaying outdated code levels
        const data = this.codeLevelData?.codeLevelTypes.filter(
            (c) => !c.isOutdated
        );

        // If the current code level is outdated it must be added in the item collection to be displayed
        // important for displaying code levels of imported cases
        if (this.selectedCodeLevelType?.isOutdated) {
            data?.push(this.selectedCodeLevelType);
        }

        return data ?? [];
    }

    get selectedCodeLevelType(): CodeLevelLookupEntity | null {
        return (
            this.codeLevelData?.codeLevelTypes.find(
                (t) => t.id === this.codeLevelSet.codeLevelTypeId
            ) ?? null
        );
    }

    get codeLevelAreas(): LookupEntity[] {
        // Not displaying outdated code levels
        const data = this.codeLevelData?.codeLevelAreas.filter(
            (c) => !c.isOutdated
        );

        // If the current code level is outdated it must be added in the item collection to be displayed
        // important for displaying code levels of imported cases
        if (this.selectedCodeLevelArea?.isOutdated) {
            data?.push(this.selectedCodeLevelArea);
        }

        return data ?? [];
    }

    get selectedCodeLevelArea(): CodeLevelArea | null {
        return (
            this.codeLevelData?.codeLevelAreas.find(
                (a) => a.id === this.codeLevelSet.codeLevelAreaId
            ) ?? null
        );
    }

    get codeLevelTopicGroups(): LookupEntity[] {
        // Not displaying outdated code levels
        const data = this.selectedCodeLevelArea?.topicGroups.filter(
            (c) => !c.isOutdated
        );

        // If the current code level is outdated it must be added in the item collection to be displayed
        // important for displaying code levels of imported cases
        if (this.selectedCodeLevelTopicGroup?.isOutdated) {
            data?.push(this.selectedCodeLevelTopicGroup);
        }

        return data ?? [];
    }

    get selectedCodeLevelTopicGroup(): CodeLevelTopicGroup | null {
        return (
            this.selectedCodeLevelArea?.topicGroups.find(
                (t) => t.id === this.codeLevelSet.codeLevelTopicGroupId
            ) ?? null
        );
    }

    get codeLevelTopicDetails(): LookupEntity[] {
        // Not displaying outdated code levels
        const data =
            this.selectedCodeLevelTopicGroup?.codeLevelTopicDetails.filter(
                (c) => !c.isOutdated
            );

        // If the current code level is outdated it must be added in the item collection to be displayed
        // important for displaying code levels of imported cases
        if (this.selectedCodeLevelTopicDetail?.isOutdated) {
            data?.push(this.selectedCodeLevelTopicDetail);
        }

        return data ?? [];
    }

    get selectedCodeLevelTopicDetail(): CodeLevelLookupEntity | null {
        return (
            this.selectedCodeLevelTopicGroup?.codeLevelTopicDetails.find(
                (t) => t.id === this.codeLevelSet.codeLevelTopicDetailId
            ) ?? null
        );
    }

    // CodeLevelSet cascading related values
    get codeLevelAreaValue(): number | null {
        return this.codeLevelSet.codeLevelAreaId;
    }
    set codeLevelAreaValue(value: number | null) {
        if (this.codeLevelSet.codeLevelAreaId !== value) {
            this.codeLevelSet.codeLevelTopicGroupId = null;
            this.codeLevelSet.codeLevelTopicDetailId = null;
        }
        this.codeLevelSet.codeLevelAreaId = value;
    }

    get codeLevelTopicGroupValue(): number | null {
        return this.codeLevelSet.codeLevelTopicGroupId;
    }
    set codeLevelTopicGroupValue(value: number | null) {
        if (this.codeLevelSet.codeLevelTopicGroupId !== value) {
            this.codeLevelSet.codeLevelTopicDetailId = null;
        }
        this.codeLevelSet.codeLevelTopicGroupId = value;
    }

    get codeLevelTopicDetailValue(): number | null {
        return this.codeLevelSet.codeLevelTopicDetailId;
    }
    set codeLevelTopicDetailValue(value: number | null) {
        this.codeLevelSet.codeLevelTopicDetailId = value;
    }
    // -------------------------------------------
}
