
import { Attachment } from "@/models/Attachment";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    computed: {
        ...caseModule.mapGetters({
            attachments: CaseGetters.Attachments
        })
    }
})
export default class CaseAttachmentSelector extends Vue {
    protected readonly attachments!: Attachment[];

    @Prop({ type: Array, required: true })
    public selectedAttachments!: number[];

    changeState(addNew: boolean, attachmentId: number): void {
        if (addNew) {
            this.selectedAttachments.push(attachmentId);
        } else {
            const index = this.selectedAttachments.indexOf(attachmentId);
            this.selectedAttachments.splice(index);
        }
    }
}
