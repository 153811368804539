export interface ListCaseFilter {
    mailboxes?: string[];
    standard: StandardFilter;
    detail: DetailFilter;
    theme: ThemeFilter;
    sortBy: SortParams;
}

export interface StandardFilter {
    titleOrContactName?: string;
    nscIdOrCaseIdOrVin?: string;
    email?: string;
    statusId?: number;
    multipleStatusIds?: number[];
    searchString?: string;
    searchType?: string;
    inAttachments?: boolean;
    createdFrom?: string;
    createdUntil?: string;
}

export interface DetailFilter {
    sourceId?: number;
    marketId?: number;
    isAllCasesExceptGerman?: boolean;
    region?: string;
    inputChannelId?: number;
    language?: string;
    vehicleSeriesOrModel?: string;
    qualified?: boolean;
    showDeleted?: boolean;
}

export interface ThemeFilter {
    codeLevelAreaId?: number;
    codeLevelBrandId?: number;
    codeLevelTopicDetailId?: number;
    codeLevelTopicGroupId?: number;
    codeLevelTypeId?: number;
    includeAllSets?: boolean;
}

export enum FullTextSearchType {
    And = "And",
    Or = "Or",
    Phrase = "Phrase"
}

export interface SortParams {
    status?: boolean;
    active?: boolean;
    email?: boolean;
    title?: boolean;
    createdDateTime?: boolean;
    lastModifiedDateTime?: boolean;
}
