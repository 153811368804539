export enum RuleType {
    AddEmail = "AddEmail",
    AddToSubject = "AddToSubject",
    Condition = "Condition",
    Email = "Email",
    EmailEsc = "EmailEsc",
    EmailEsc2 = "EmailEsc2",
    Forward = "Forward",
    Subject = "Subject",
    Title = "Title"
}
