import { CaseEvent } from "@/models/cases/CaseEvent";
import { CaseEventType } from "@/models/cases/CaseEventType";
import CaseEventFlow from "@/models/cases/CaseEventFlow";
import caseEventFlowDirections from "@/consts/caseEventFlowDirections";

export function getCaseEventFlow(caseEvent: CaseEvent): CaseEventFlow {
    const gkk = caseEventFlowDirections.GKK;
    const customer = caseEventFlowDirections.CUSTOMER;
    const market = caseEventFlowDirections.MARKET;
    const internal = caseEventFlowDirections.INTERNAL;
    switch (caseEvent.caseEventType.name) {
        case CaseEventType.Import:
            return {
                from: customer,
                to: gkk
            };
        case CaseEventType.Comment:
            return {
                from: gkk
            };
        case CaseEventType.Reply:
            return {
                from: gkk,
                to: caseEvent.handedToContact ? customer : market
            };
        case CaseEventType.Forward:
        case CaseEventType.ForwardEsc:
        case CaseEventType.ForwardEsc2:
            return {
                from: gkk,
                to: market
            };
        case CaseEventType.Market:
            return {
                from: market,
                to: gkk
            };
        case CaseEventType.CommentBMWIFM:
            return {
                from: internal
            };
        default:
            return {
                from: {
                    label: "???",
                    color: "grey"
                }
            };
    }
}
