
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import CaseAttachmentSelector from "@/components/CaseAttachmentSelector";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import BaseButton from "@/components/BaseButton";
import { VuetifyForm } from "@/models/VuetifyForm";
import IFramePopup from "@/components/IFramePopup";
import { Case } from "@/models/cases/Case";
import { CaseActions } from "@/store/modules/case/actions";
import { ForwardCaseData } from "@/models/cases/ForwardCaseData";

@Component({
    components: {
        CaseAttachmentSelector,
        BaseButton,
        IFramePopup
    },
    methods: {
        ...caseModule.mapActions({
            forwardCase: CaseActions.Forward,
            checkIsReminderProcessAvailable:
                CaseActions.CheckIsReminderProcessAvailable,
            getForwardEmailPreviewForCase:
                CaseActions.GetForwardEmailPreviewForCase
        })
    },
    computed: {
        ...caseModule.mapGetters({
            case: CaseGetters.Case,
            isLoading: CaseGetters.IsLoading
        })
    }
})
export default class ForwardCasePopup extends Vue {
    private readonly forwardCase!: (data: ForwardCaseData) => Promise<void>;
    private readonly checkIsReminderProcessAvailable!: () => Promise<boolean>;
    private readonly getForwardEmailPreviewForCase!: (
        payload: ForwardCaseData
    ) => Promise<string>;

    protected readonly case!: Case;
    protected readonly isLoading!: boolean;

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    $refs!: {
        form: VuetifyForm;
    };

    protected title = "";
    protected content = "";
    protected reminderProcessEnabled = false;
    protected selectedAttachments: number[] = [];

    protected reminderProcessAvailable = false;

    protected previewVisible = false;
    protected previewHtml = "";

    @Watch("visible", { immediate: true })
    async visibleChanged(visible: boolean): Promise<void> {
        if (visible) {
            if (this.case?.title) {
                this.title = this.case.title;
            }
            this.reminderProcessEnabled = false;
            this.reminderProcessAvailable =
                await this.checkIsReminderProcessAvailable();
        } else {
            this.$refs.form?.reset();
        }
    }

    async forward(): Promise<void> {
        const result = this.$refs.form.validate();
        if (result) {
            await this.forwardCase({
                title: this.title,
                content: this.content,
                reminderProcessEnabled: this.reminderProcessEnabled,
                attachments: this.selectedAttachments
            });
            this.popupVisible = false;
        }
    }

    async preview(): Promise<void> {
        this.previewHtml = "";
        this.previewHtml = await this.getForwardEmailPreviewForCase({
            title: this.title,
            content: this.content,
            reminderProcessEnabled: this.reminderProcessEnabled,
            attachments: this.selectedAttachments
        });
        this.previewVisible = true;
    }
}
