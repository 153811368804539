
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { Rule } from "@/models/rules/Rule";
import { Case } from "@/models/cases/Case";
import ruleHelper from "@/services/ruleHelper";

@Component({
    components: {
        BaseButton
    },
    methods: {
        ...caseModule.mapActions({
            loadRules: CaseActions.LoadRules
        })
    },
    computed: {
        ...caseModule.mapGetters({
            rules: CaseGetters.Rules,
            case: CaseGetters.Case,
            isLoading: CaseGetters.IsLoading
        })
    }
})
export default class RulesOverviewPopup extends Vue {
    private readonly loadRules!: () => Promise<void>;

    protected readonly rules!: Rule[];
    protected readonly case!: Case;
    protected readonly isLoading!: boolean;

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Watch("visible")
    visibleChanged(value: boolean): void {
        if (value) {
            this.loadRules();
        }
    }

    isMarketRule(rule: Rule): boolean {
        if (!this.case.marketId) return false;

        return rule.marketIds.includes(this.case.marketId);
    }

    getRuleContentDescription(rule: Rule): string {
        return ruleHelper.getRuleDescription(rule);
    }
}
