export const roles = {
    admin: "Admin",
    superUserInternational: "Super User International",
    agentInternational: "Agent International",
    superGermanMarket: "Super User C1-DE",
    germanMarket1stLevel: "User C1-DE (1st level)",
    germanMarket3rdLevel: "User C1-DE (3rd level)"
};

export const germanMarketRoles = [
    roles.germanMarket1stLevel,
    roles.germanMarket3rdLevel,
    roles.superGermanMarket
];

export enum RoleType {
    BMW_Admin = "BMW Admin",
    IFM_Admin = "IFM Admin",
    Agent = "Agent",
    Reader = "Reader",
    Report_Reader = "Report Reader"
}
