
import { Case } from "@/models/cases/Case";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker";
import {
    getSelectDataSource,
    getSelectDataSourceFromStrings,
    getSortedSelectDataSource
} from "@/services/dataSourceUtils";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { module as casesModule } from "@/store/modules/cases";
import { LookupEntity } from "@/models/LookupEntity";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { Market } from "@/models/markets/Market";
import { CasesGetters } from "@/store/modules/cases/getters";
import CaseUpdate from "@/models/cases/CaseUpdate";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import { CasesActions } from "@/store/modules/cases/actions";

@Component({
    components: { DatePicker },
    computed: {
        ...caseModule.mapGetters({
            originalCase: CaseGetters.Case
        }),
        ...casesModule.mapGetters({
            info: CasesGetters.Info,
            isInfoLoading: CasesGetters.IsLoading
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo
        })
    }
})
export default class CaseInfoEditor extends Vue {
    protected readonly originalCase!: Case;

    @Prop({ type: Object, required: true })
    public readonly caseModel!: Partial<CaseUpdate>;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly isDisabledFieldsEmpty!: boolean;

    protected readonly info!: CaseInfo;
    protected readonly markets!: Market[];
    protected readonly isInfoLoading!: boolean;

    protected readonly loadInfo!: () => Promise<void>;

    protected get sources(): LookupEntity<number | null>[] {
        return getSelectDataSource(this.info.sources, false);
    }
    protected get statuses(): LookupEntity<number | null>[] {
        return getSortedSelectDataSource(this.info.statuses);
    }
    protected get escalationLevels(): LookupEntity<number | null>[] {
        return getSelectDataSource(this.info.escalationLevels);
    }
    protected get inputChannels(): LookupEntity<number | null>[] {
        return getSelectDataSource(this.info.inputChannels, false);
    }
    protected get socialMediaChannels(): LookupEntity<number | null>[] {
        return getSelectDataSource(this.info.socialMediaChannels);
    }
    protected get socialMediaAgencies(): LookupEntity<number | null>[] {
        return getSelectDataSource(this.info.socialMediaAgencies);
    }
    protected get mailboxes(): LookupEntity<string | null>[] {
        return getSelectDataSourceFromStrings(
            this.info.mailboxes.map((m) => m.email)
        );
    }
    protected get marketDataSource(): LookupEntity<number | null>[] {
        return getSelectDataSource(
            this.markets.map((m) => ({
                id: m.marketId,
                name: `${m.countryCode} / ${m.name} (${m.region})`
            }))
        );
    }

    get showSocialMedia(): boolean {
        return (
            this.caseModel.inputChannelId ===
            this.info.inputChannels.find((i) => i.name === "Social Media")?.id
        );
    }

    created(): void {
        this.loadInfo();
    }
}
