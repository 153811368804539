import {
    DetailFilter,
    FullTextSearchType,
    StandardFilter,
    ThemeFilter
} from "@/models/cases/CaseFilter";

export const standardFilterTypeValues: Readonly<Required<StandardFilter>> = {
    titleOrContactName: "",
    nscIdOrCaseIdOrVin: "",
    email: "",
    statusId: 0,
    multipleStatusIds: [0],
    searchString: FullTextSearchType.Phrase,
    searchType: "",
    inAttachments: false,
    createdFrom: "",
    createdUntil: ""
};

export const detailFilterTypeValues: Readonly<Required<DetailFilter>> = {
    sourceId: 0,
    isAllCasesExceptGerman: false,
    marketId: 0,
    region: "",
    inputChannelId: 0,
    language: "",
    vehicleSeriesOrModel: "",
    qualified: false,
    showDeleted: false
};

export const themeFilterTypeValues: Readonly<Required<ThemeFilter>> = {
    codeLevelAreaId: 0,
    codeLevelBrandId: 0,
    codeLevelTopicDetailId: 0,
    codeLevelTopicGroupId: 0,
    codeLevelTypeId: 0,
    includeAllSets: false
};
