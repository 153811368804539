import { Contact } from "@/models/cases/Contact";

function getFullName(contact: Contact): string {
    return `${contact.firstName} ${contact.lastName}`;
}

function getContactEmails(contact: Contact): string[] {
    const result: string[] = [];
    const { email1, email2, email3, email4, email5 } = contact;
    for (const email of [email1, email2, email3, email4, email5]) {
        if (email) result.push(email);
    }
    return result;
}

export default {
    getFullName,
    getContactEmails
};
