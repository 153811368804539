export enum MileageUnit {
    km = "km",
    miles = "miles"
}

export enum Drivetrain {
    Ice = "ICE",
    Phev = "PHEV",
    Bev = "BEV"
}

export interface ContactCar {
    contactCarId: number;
    brandId?: number;
    series?: string;
    model?: string;
    firstRegistration?: string | Date;
    mileage?: number;
    mileageUnit?: MileageUnit | null;
    drivetrain?: Drivetrain | null;
    vin: string;
    countryOfRegistrationCode: string | null;
}
