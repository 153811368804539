
import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { Attachment } from "@/models/Attachment";
import BaseDialog from "@/components/BaseDialog";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: { BaseDialog },
    methods: caseModule.mapActions({
        deleteAttachment: CaseActions.DeleteAttachment
    }),
    computed: caseModule.mapGetters({
        attachments: CaseGetters.Attachments,
        isLoading: CaseGetters.IsLoading
    })
})
export default class AttachmentDeleteForm extends Vue {
    readonly attachments!: Attachment[];
    readonly isLoading!: boolean;
    readonly deleteAttachment!: (payload: number) => Promise<void>;

    isDeleteAttachmentPopupVisible = false;
    deletingAttachmentId: number | null = null;

    @Ref()
    form!: VuetifyForm;

    showDeleteAttachmentPopup(): void {
        if (!this.form.validate()) return;

        this.isDeleteAttachmentPopupVisible = true;
    }

    async confirmAttachmentDelete(): Promise<void> {
        if (this.deletingAttachmentId) {
            await this.deleteAttachment(this.deletingAttachmentId);
            this.form?.reset(); // the form may be null after a last attachment was deleted, then the form disapears
        }
        this.isDeleteAttachmentPopupVisible = false;
    }
}
