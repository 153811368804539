
import Vue from "vue";
import Component from "vue-class-component";
import { VuetifyForm } from "@/models/VuetifyForm";
import postQualificationApi from "@/api/postQualificationApi";
import { PostQualificationLanguage } from "@/models/postQualification/PostQualificationLanguage";
import { Prop, Watch } from "vue-property-decorator";
import { makeRequest } from "@/services/requestUtils";
import { getSelectDataSourceFromEnum } from "@/services/dataSourceUtils";
import FieldData from "@/services/fieldData";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";

@Component({
    methods: caseModule.mapActions({
        reloadCase: CaseActions.Reload
    })
})
export default class PostQualificationCreateForm
    extends Vue
    implements VuetifyForm
{
    @Prop({ type: Number, required: true }) readonly contactId!: number;
    @Prop({ type: Number, required: true }) readonly caseId!: number;
    @Prop(String) readonly defaultEmail!: string;

    readonly reloadCase!: () => Promise<void>;

    readonly languageItems = getSelectDataSourceFromEnum(
        PostQualificationLanguage,
        false
    );

    readonly details = {
        nameDetails: "Name details",
        addressDetails: "Address details",
        vinDetails: "Vehicle details"
    };

    selectedDetails: Array<string> = [];
    email = "";
    text = "";
    language: PostQualificationLanguage | "" = "";

    isSubmitting = false;

    emailData = new FieldData("Email address", {
        required: true,
        email: true
    });

    languageData = new FieldData("Language", {
        required: true
    });

    detailsRules = [
        (selectedDetails: Array<string>): boolean | string =>
            selectedDetails.length > 0 ||
            "At least one field (Name, Address, Vin) must be selected."
    ];

    $refs!: {
        form: VuetifyForm;
    };

    async submit(): Promise<void> {
        if (this.$refs.form.validate()) {
            const request = () =>
                postQualificationApi.createPostQualification({
                    contactId: this.contactId,
                    caseId: this.caseId,
                    email: this.email,
                    language: this.language as PostQualificationLanguage,
                    hasNameDetails: this.selectedDetails.includes(
                        this.details.nameDetails
                    ),
                    hasAddressDetails: this.selectedDetails.includes(
                        this.details.addressDetails
                    ),
                    hasCarDetails: this.selectedDetails.includes(
                        this.details.vinDetails
                    ),
                    text: this.text
                });

            await makeRequest(request, (val) => (this.isSubmitting = val));
            await this.reloadCase();
            this.$emit("afterSubmit");
        }
    }

    validate(): boolean {
        return this.$refs.form.validate();
    }
    reset(): void {
        this.$refs.form.reset();
    }
    resetValidation(): void {
        this.$refs.form.resetValidation();
    }

    @Watch("defaultEmail", { immediate: true })
    onEmailPropChanging(defaultEmail: string): void {
        this.email = defaultEmail;
    }
}
