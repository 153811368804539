
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ContactSearchForm from "@/components/ContactSearchForm";
import ContactEditor from "@/components/ContactEditor";
import { Contact } from "@/models/cases/Contact";
import { contactModule } from "@/store/modules/contact";
import { ContactActions } from "@/store/modules/contact/actions";
import { ContactGetters } from "@/store/modules/contact/getters";
import { ContactMutation } from "@/store/modules/contact/mutations";
import { VuetifyForm } from "@/models/VuetifyForm";
import PostQualificationCreateDialog from "@/components/PostQualificationCreateDialog";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import { Case } from "@/models/cases/Case";
import contactUtils from "@/services/contactUtils";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { userHasClaims } from "@/services/userUtils";
import { CREATE_POSTQUALIFICATION_PERMISSION } from "@/consts/customClaims";
import { CasesGetters } from "@/store/modules/cases/getters";
import { module as casesModule } from "@/store/modules/cases";
import { CasesActions } from "@/store/modules/cases/actions";

@Component({
    components: {
        ContactSearchForm,
        ContactEditor,
        PostQualificationCreateDialog
    },
    methods: {
        ...contactModule.mapActions({
            createContact: ContactActions.CreateContact,
            updateContact: ContactActions.UpdateContact,
            loadContactById: ContactActions.LoadContactById
        }),
        ...contactModule.mapMutations({
            setContact: ContactMutation.SetContact
        }),
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo
        })
    },
    computed: {
        ...casesModule.mapGetters({
            caseInfo: CasesGetters.Info,
            isInfoLoading: CasesGetters.IsLoading
        }),
        ...contactModule.mapGetters({
            loading: ContactGetters.IsLoading,
            contact: ContactGetters.Contact
        }),
        ...caseModule.mapGetters({
            $case: CaseGetters.Case
        })
    }
})
export default class ContactReference extends Vue {
    private readonly createContact!: (
        contact: Partial<Contact>
    ) => Promise<void>;
    private readonly updateContact!: (
        contact: Partial<Contact>
    ) => Promise<void>;
    private readonly loadContactById!: (contactId: number) => Promise<void>;
    private readonly setContact!: (contact: Contact | null) => void;
    private readonly loadInfo!: () => Promise<void>;

    protected readonly loading!: boolean;
    protected readonly contact!: Contact | null;
    protected readonly $case!: Case | null;
    protected readonly caseInfo!: CaseInfo;
    protected readonly isInfoLoading!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    protected newContact: Partial<Contact> = {};
    protected isPostQualificationCreateDialogOpened = false;
    protected isEmailInBlacklistMessageShowing = false;

    get contactReferenceLabel(): string {
        if (this.contact) {
            const fullName = contactUtils.getFullName(this.contact).trim();
            if (fullName) return fullName;
        }

        return "Contact Reference";
    }

    get emailsInBlacklist(): string[] {
        if (!this.contact) return [];

        const contactEmails = contactUtils.getContactEmails(this.contact);
        return contactEmails.filter((e) =>
            this.caseInfo.blacklistEmails.includes(e)
        );
    }

    get isPostQualificationAllowed(): boolean {
        return userHasClaims([CREATE_POSTQUALIFICATION_PERMISSION]);
    }

    $refs!: {
        form: VuetifyForm;
    };

    get currentContact(): Partial<Contact> {
        if (this.contact) {
            return { ...this.contact, cars: [] };
        }
        return {};
    }

    created(): void {
        this.loadInfo();
    }

    createNewContact(): void {
        const result = this.$refs.form.validate();
        if (result) {
            this.createContact(this.newContact).then(() => {
                this.$emit("set-contact");
            });
        }
    }

    selectContact(contact: Contact): void {
        this.loadContactById(contact.contactId).then(() => {
            this.$emit("set-contact");
        });
    }

    updateCurrentContact(): void {
        this.updateContact(this.currentContact);
    }

    removeCurrentContact(): void {
        this.setContact(null);
        this.$emit("remove-contact");
    }

    openPostQualificationDialog(): void {
        this.isPostQualificationCreateDialogOpened = true;
    }
}
