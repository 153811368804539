
import Vue from "vue";
import { Component, ModelSync } from "vue-property-decorator";
import BaseDialog from "@/components/BaseDialog";
import { CaseActions } from "@/store/modules/case/actions";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";

@Component({
    components: { BaseDialog },
    methods: {
        ...caseModule.mapActions({ duplicateCase: CaseActions.Duplicate })
    },
    computed: caseModule.mapGetters({ isLoading: CaseGetters.IsLoading })
})
export default class DuplicateDialog extends Vue {
    readonly duplicateCase!: () => Promise<void>;

    readonly isLoading!: boolean;

    @ModelSync("value", "input", { type: Boolean })
    valueSync!: boolean;

    async duplicate(): Promise<void> {
        await this.duplicateCase();
        this.valueSync = false;
    }
}
