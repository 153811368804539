
import { CaseListItem } from "@/models/cases/CaseListItem";
import { module as casesModule } from "@/store/modules/cases";
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import DynamicTitle from "@/components/DynamicTitle";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { Routes } from "@/router/routes";
import { PaginationInfo } from "@/models/PaginationInfo";
import { PaginationResult } from "@/models/PaginationResult";
import MailboxFilter from "@/components/MailboxFilter";
import { ListCaseFilter } from "@/models/cases/CaseFilter";
import CaseSideFilter from "@/components/CaseSideFilter";
import { DataOptions } from "vuetify";
import { userHasClaims } from "@/services/userUtils";
import { DELETE_PERMISSION } from "@/consts/customClaims";
import { scrollModule } from "@/store/modules/scroll";
import { ScrollActions } from "@/store/modules/scroll/actions";
import queryService from "@/services/queryService";

import { getCaseFilterFromQuery } from "@/services/queryFactoryUtil";
import { CasesActions } from "@/store/modules/cases/actions";
import { CasesGetters } from "@/store/modules/cases/getters";
import { CaseInfo } from "@/models/cases/CaseInfo";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { codeLevelModule } from "@/store/modules/codeLevel";
import { CodeLevelGetters } from "@/store/modules/codeLevel/getters";
import { MarketActions } from "@/store/modules/market/actions";
import { CodeLevelActions } from "@/store/modules/codeLevel/actions";
import { Market } from "@/models/markets/Market";
import { CodeLevelData } from "@/models/CodeLevelData";
import { Route } from "vue-router";
import CaseList from "@/components/CaseList";

@Component({
    components: {
        DynamicTitle,
        ConfirmationDialog,
        MailboxFilter,
        CaseSideFilter,
        CaseList
    },
    computed: {
        ...casesModule.mapGetters({
            info: CasesGetters.Info,
            paginationData: CasesGetters.PaginationData,
            isLoading: CasesGetters.IsLoading,
            favoriteCases: CasesGetters.FavoriteCases
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        }),
        ...codeLevelModule.mapGetters({
            codeLevelData: CodeLevelGetters.CodeLevelData
        })
    },
    methods: {
        ...casesModule.mapActions({
            loadInfo: CasesActions.LoadInfo,
            loadFilteredCases: CasesActions.LoadFilteredCasesWithPagination,
            deleteCase: CasesActions.DeleteCase,
            loadFavoriteCases: CasesActions.LoadFavoriteCasesForUser,
            markFavorite: CasesActions.MarkCaseAsFavorite,
            unmarkFavorite: CasesActions.UnmarkCaseAsFavorite
        }),
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets
        }),
        ...codeLevelModule.mapActions({
            loadCodeLevels: CodeLevelActions.LoadCodeLevels
        }),
        ...scrollModule.mapActions({
            saveScrollPosition: ScrollActions.SaveElementScrollPosition,
            setScrollPosition: ScrollActions.SetScrollPositionToElement
        })
    }
})
export default class CaseOverview extends Vue {
    protected readonly paginationData!: PaginationResult<CaseListItem>;
    protected readonly isLoading!: boolean;
    protected readonly info!: CaseInfo;
    protected readonly markets!: Market[];
    protected readonly codeLevelData!: CodeLevelData | null;
    protected readonly favoriteCases!: number[];

    @Ref()
    protected readonly contentElement!: Element;

    protected deleteDialog = false;
    protected isOptionsSettedUp = false;

    private readonly defaultSortBy = "lastModifiedDateTime";
    private readonly defaultSortDesc = false;

    private readonly loadFilteredCases!: (payload: {
        caseFilter: ListCaseFilter;
        paginationInfo: PaginationInfo;
    }) => Promise<void>;
    private readonly loadInfo!: () => Promise<void>;
    private readonly loadMarkets!: () => Promise<void>;
    private readonly loadCodeLevels!: () => Promise<void>;
    private readonly deleteCase!: (caseId: number) => Promise<void>;
    private readonly loadFavoriteCases!: () => Promise<void>;
    protected readonly markFavorite!: (caseId: number) => Promise<void>;
    protected readonly unmarkFavorite!: (caseId: number) => Promise<void>;
    private readonly saveScrollPosition!: (payload: {
        key: string;
        element: Element;
    }) => void;
    private readonly setScrollPosition!: (payload: {
        key: string;
        element: Element;
    }) => void;

    get options(): DataOptions {
        const queryValues = queryService.getValues(this.$route.query, {
            page: 0,
            itemsPerPage: 0,
            sortBy: [""],
            sortDesc: [false]
        });

        return {
            page: queryValues.page ?? 0,
            itemsPerPage: queryValues.itemsPerPage ?? 0,
            sortBy: queryValues.sortBy ?? [],
            sortDesc: queryValues.sortDesc ?? [],
            multiSort: false,
            mustSort: false,
            groupBy: [],
            groupDesc: []
        };
    }
    set options({ page, itemsPerPage, sortBy, sortDesc }: DataOptions) {
        queryService.setValues(this.$route.query, {
            page,
            itemsPerPage,
            sortBy,
            sortDesc
        });
    }

    async created(): Promise<void> {
        await this.setupDefaultOptions();

        this.loadInfo();
        this.loadMarkets();
        this.loadCodeLevels();
        this.loadFavoriteCases();
    }

    protected async setupDefaultOptions(): Promise<void> {
        const { page, itemsPerPage, sortBy, sortDesc } = this.options;

        if (!page || !itemsPerPage) {
            await queryService.setValues(this.$route.query, {
                page: 1,
                itemsPerPage: 20
            });
        }
        if (!sortBy.length && !sortDesc.length) {
            await queryService.setValues(this.$route.query, {
                sortBy: [this.defaultSortBy],
                sortDesc: [this.defaultSortDesc]
            });
        }
        this.isOptionsSettedUp = true;
    }

    async mounted(): Promise<void> {
        const scrollPositionKey = "caseOverview";
        this.setScrollPosition({
            key: scrollPositionKey,
            element: this.contentElement
        });
        this.contentElement.addEventListener("scroll", () => {
            this.saveScrollPosition({
                key: scrollPositionKey,
                element: this.contentElement
            });
        });
    }

    get isDeletingAllowed(): boolean {
        return userHasClaims([DELETE_PERMISSION]);
    }

    editItem(caseId: number | null): void {
        if (caseId) {
            this.$router.push(Routes.CaseEdit + caseId);
        }
    }

    deleteItem(caseId: number | null): void {
        if (caseId) {
            this.deleteCase(caseId);
        }
    }

    @Watch("$route.query")
    async onQueryChange(query: Route["query"]): Promise<void> {
        // Disable cases loading on default options setup
        if (!this.isOptionsSettedUp) return;

        const caseFilter = getCaseFilterFromQuery(query);
        await this.loadFilteredCases({
            caseFilter,
            paginationInfo: {
                skip: (this.options.page - 1) * this.options.itemsPerPage,
                take: this.options.itemsPerPage
            }
        });
    }
}
