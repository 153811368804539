import {
    BRAND,
    CASE_SOURCE,
    CODE_LEVEL_2,
    EXCLUDE_MARKET,
    MARKETS
} from "@/consts/customClaims";
import { getCurrentUser, getUserClaimValue } from "./userUtils";
import { first } from "lodash";
import { LookupEntity } from "@/models/LookupEntity";
import { DetailFilter, ThemeFilter } from "../models/cases/CaseFilter";
import { Market } from "@/models/markets/Market";

function getUserRestrictionPreset(
    sources: LookupEntity<number>[],
    brands: LookupEntity<number>[],
    codeLevelAreas: LookupEntity<number>[],
    markets: Market[]
): Pick<DetailFilter, "sourceId" | "marketId" | "isAllCasesExceptGerman"> &
    Pick<ThemeFilter, "codeLevelBrandId" | "codeLevelAreaId"> {
    const claimSource = first(getUserClaimValue(CASE_SOURCE));
    const claimBrand = first(getUserClaimValue(BRAND));
    const claimCodeLevel2 = first(getUserClaimValue(CODE_LEVEL_2));
    const claimExcludeMarket = first(getUserClaimValue(EXCLUDE_MARKET));

    const claimMarkets = getUserClaimValue(MARKETS);
    const firstUpperClaimMarket = first(claimMarkets)?.toUpperCase();

    let marketCountryCode: string | undefined;
    if (firstUpperClaimMarket === "CUSTOM") {
        const userMarkets = getCurrentUser()?.markets;
        marketCountryCode =
            userMarkets?.length === 1 ? first(userMarkets) : undefined;
    } else if (firstUpperClaimMarket !== "ALL") {
        marketCountryCode =
            claimMarkets?.length === 1 ? first(claimMarkets) : undefined;
    }

    const source = sources.find(
        (s) => s.name.toUpperCase() === claimSource?.toUpperCase()
    );
    const market = markets.find(
        (m) => m.countryCode.toUpperCase() === marketCountryCode?.toUpperCase()
    );
    const brand = brands.find(
        (c) => c.name.toUpperCase() === claimBrand?.toUpperCase()
    );
    const codeLevelArea = codeLevelAreas.find(
        (c) => c.name.toUpperCase() === claimCodeLevel2?.toUpperCase()
    );

    return {
        sourceId: source?.id,
        marketId: market?.marketId,
        codeLevelBrandId: brand?.id,
        codeLevelAreaId: codeLevelArea?.id,
        isAllCasesExceptGerman:
            claimExcludeMarket?.toUpperCase() === "DE" ? true : undefined
    };
}

export default {
    getUserRestrictionPreset
};
