
import { CaseListItem } from "@/models/cases/CaseListItem";
import { PaginationResult } from "@/models/PaginationResult";
import Vue from "vue";
import { Component, Emit, Prop, PropSync } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import dateFormat from "dateformat";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import * as caseStatuses from "@/consts/caseStatuses";
import * as caseSources from "@/consts/caseSources";

@Component({
    components: { ConfirmationDialog }
})
export default class CaseList extends Vue {
    protected readonly caseStatuses = caseStatuses;
    protected readonly caseSources = caseSources;

    @Prop({ type: Object, required: true })
    protected readonly paginationData!: PaginationResult<CaseListItem>;

    @PropSync("options", { type: Object, required: true })
    public readonly optionsSync!: DataOptions;

    @Prop({ type: Boolean, default: false })
    public readonly isLoading!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly deleteButtonVisible!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly lockButtonVisible!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly favoriteButtonVisible!: boolean;

    @Prop({ type: Array, default: () => [] })
    public readonly favoriteCases!: number[];

    protected readonly headers: DataTableHeader<CaseListItem>[] = [
        {
            text: "",
            value: "icon",
            sortable: false,
            filterable: false,
            width: 50
        },
        {
            text: "Status",
            value: "status",
            sort: (c: CaseListItem): number => c.status?.sortOrder ?? 0
        },
        { text: "Akt", value: "communicationEventsCount", width: 80 },
        { text: "Email", value: "email", width: 120 },
        { text: "Case Title", value: "title" },
        { text: "Created ", value: "createdDateTime", width: 130 },
        { text: "Edited ", value: "lastModifiedDateTime", width: 200 },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    protected deleteDialog = false;
    private selectedItemId: number | null = null;

    isCaseFavorite(caseItem: CaseListItem): boolean {
        return this.favoriteCases.includes(caseItem.caseId);
    }

    @Emit("edit")
    editItem(caseModel?: CaseListItem): number | null {
        return caseModel?.caseId ?? null;
    }

    deleteItem(caseModel: CaseListItem): void {
        this.selectedItemId = caseModel.caseId;
        this.deleteDialog = true;
    }

    @Emit("delete")
    confirmDeleteMarket(): number | null {
        return this.selectedItemId;
    }

    @Emit("mark-favorite")
    markFavorite(caseItem: CaseListItem): number {
        return caseItem.caseId;
    }

    @Emit("unmark-favorite")
    unmarkFavorite(caseItem: CaseListItem): number {
        return caseItem.caseId;
    }

    getCaseIconColor($case: CaseListItem): string {
        return !$case.status || $case.status.name == "New"
            ? "red"
            : $case.status.name === "Closed"
            ? "green"
            : "amber accent-2";
    }

    getFormattedDateString(dateString: string, showTime?: boolean): string {
        if (!showTime) {
            return dateFormat(new Date(dateString), "dd.mm.yyyy");
        } else {
            return dateFormat(new Date(dateString), "dd.mm.yyyy HH:MM:ss");
        }
    }
}
