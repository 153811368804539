
import { CaseEvent } from "@/models/cases/CaseEvent";
import { CaseEventType } from "@/models/cases/CaseEventType";
import { User } from "@/models/users/User";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CaseEventViewer from "@/components/CaseEventViewer";
import CaseEventEditor from "@/components/CaseEventEditor";
import { getCurrentUser, userHasClaims } from "@/services/userUtils";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { CaseEventEditDetails } from "@/models/cases/CaseEventEditDetails";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CaseEventReview from "@/components/CaseEventReview";
import RulesOverviewPopup from "@/components/RulesOverviewPopup";
import ForwardCasePopup from "@/components/ForwardCasePopup";
import SendEmailPopup from "@/components/SendEmailPopup";
import ReplyPopup from "@/components/ReplyPopup";
import { Attachment } from "@/models/Attachment";
import AttachmentButton from "@/components/AttachmentButton";
import CaseUpdate from "@/models/cases/CaseUpdate";
import { Routes } from "@/router/routes";
import AttachmentDeleteForm from "@/components/AttachmentDeleteForm";
import { DELETE_ATTACHMENT_PERMISSION } from "@/consts/customClaims";

@Component({
    components: {
        CaseEventViewer,
        CaseEventEditor,
        ConfirmationDialog,
        CaseEventReview,
        RulesOverviewPopup,
        ForwardCasePopup,
        SendEmailPopup,
        ReplyPopup,
        AttachmentButton,
        AttachmentDeleteForm
    },
    methods: {
        ...caseModule.mapActions({
            addNewComment: CaseActions.AddComment,
            updateCaseEvent: CaseActions.UpdateComment,
            deleteCaseEvent: CaseActions.DeleteEvent,
            convertToNewCaseEvent: CaseActions.ConvertToNewCase
        })
    },
    computed: {
        ...caseModule.mapGetters({
            caseEvents: CaseGetters.Events,
            attachments: CaseGetters.Attachments,
            loading: CaseGetters.IsLoading
        })
    }
})
export default class CaseEventList extends Vue {
    private readonly addNewComment!: (
        payload: CaseEventEditDetails
    ) => Promise<void>;
    private readonly updateCaseEvent!: (payload: {
        caseEventId: number;
        caseEventEditDetails: CaseEventEditDetails;
    }) => Promise<void>;
    protected readonly deleteCaseEvent!: (payload: number) => Promise<void>;
    protected readonly convertToNewCaseEvent!: (
        payload: number
    ) => Promise<void>;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    @Prop({ type: Object, default: false })
    public readonly currentCase!: CaseUpdate;

    protected readonly caseEvents!: CaseEvent[];
    protected readonly attachments!: Attachment[];
    protected readonly loading!: CaseEvent[];

    protected isDeletePopupVisible = false;
    protected isRulesOverviewPopupVisible = false;
    protected isSendEmailPopupVisible = false;
    protected isForwardEmailPopupVisible = false;
    protected isReplyEventPopupVisible = false;

    protected replyCaseEventId: number | null = null;
    protected deletingCaseEventId: number | null = null;

    protected readonly colors = {
        [CaseEventType.Comment]: "purple",
        [CaseEventType.Reply]: "teal",
        [CaseEventType.Forward]: "light-blue",
        [CaseEventType.Market]: "primary",
        [CaseEventType.ForwardEsc]: "cyan",
        [CaseEventType.ForwardEsc2]: "cyan",
        [CaseEventType.CommentBMWIFM]: "primary"
    };

    getUserInitial(user?: User): string {
        if (!user) user = getCurrentUser();
        return `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`;
    }

    getFormattedDate(strDate: string): string {
        const date = new Date(strDate);
        return date.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        });
    }

    get canUserDeleteAttachments(): boolean {
        return userHasClaims([DELETE_ATTACHMENT_PERMISSION]);
    }

    get groupedCaseEvents(): unknown {
        const groups = this.caseEvents.reduce(
            (groups: { [K: string]: CaseEvent[] }, caseEvent) => {
                const date = caseEvent.createdAtDatetime.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(caseEvent);
                return groups;
            },
            {}
        );

        // To add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
            return {
                date,
                events: groups[date]
            };
        });
        return groupArrays;
    }

    protected showForwardEmailPopup(): void {
        this.isForwardEmailPopupVisible = true;
        this.$emit("save");
    }

    protected showSendEmailPopup(): void {
        this.isSendEmailPopupVisible = true;
        this.$emit("save");
    }

    async newCaseEvent(
        details: CaseEventEditDetails,
        close: () => void
    ): Promise<void> {
        await this.addNewComment(details);
        close();
    }

    async editCaseEvent(
        caseEventId: number,
        caseEventEditDetails: CaseEventEditDetails,
        close: () => void
    ): Promise<void> {
        await this.updateCaseEvent({ caseEventId, caseEventEditDetails });
        close();
    }

    showDeletePopup(caseEventId: number): void {
        this.isDeletePopupVisible = true;
        this.deletingCaseEventId = caseEventId;
    }

    confirmDelete(): void {
        this.isDeletePopupVisible = false;
        if (this.deletingCaseEventId) {
            this.deleteCaseEvent(this.deletingCaseEventId);
        }
        this.deletingCaseEventId = null;
    }

    async convertToNewCase(caseEventId: number): Promise<void> {
        const newCaseId = await this.convertToNewCaseEvent(caseEventId);
        this.$router.push(Routes.CaseEdit + newCaseId);
    }

    replyEvent(caseEventId: number): void {
        this.isReplyEventPopupVisible = true;
        this.replyCaseEventId = caseEventId;
    }

    scrollToEvent(event: CaseEvent): void {
        const wrapper = document.querySelector(".wrapper") as HTMLElement;

        const id = `#case-event-${event.caseEventId}`;
        this.$vuetify.goTo(id, {
            container: wrapper,
            duration: 300,
            offset: 0,
            easing: "easeInOutQuint"
        });
    }
}
