
import Vue from "vue";
import { Component, ModelSync } from "vue-property-decorator";
import BaseDialog from "@/components/BaseDialog";
import { Routes } from "@/router/routes";
import { caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import { CaseActions } from "@/store/modules/case/actions";

@Component({
    components: { BaseDialog },
    computed: caseModule.mapGetters({ isLoading: CaseGetters.IsLoading }),
    methods: caseModule.mapActions({ markCaseAsSpam: CaseActions.MarkAsSpam })
})
export default class SpamDialog extends Vue {
    readonly isLoading!: boolean;

    readonly markCaseAsSpam!: () => Promise<void>;

    @ModelSync("value", "input", { type: Boolean })
    valueSync!: boolean;

    async markAsSpam(): Promise<void> {
        await this.markCaseAsSpam();
        this.valueSync = false;
        this.$router.push(Routes.CaseOverview);
    }
}
