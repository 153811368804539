
import { Contact } from "@/models/cases/Contact";
import { defaultNullPlaceholder } from "@/services/dataSourceUtils";
import { Component, Prop } from "vue-property-decorator";
import ContactFormBase from "../ContactFromBase/ContactFormBase.vue";

@Component
export default class ContactEditor extends ContactFormBase {
    @Prop({ type: Object, required: true })
    public readonly contact!: Partial<Contact>;

    @Prop({ type: Boolean, default: false })
    public readonly readOnly!: boolean;

    public get countriesWithNull(): { name: string; code: string | null }[] {
        return [
            { name: defaultNullPlaceholder, code: null },
            ...this.countries
        ];
    }
}
